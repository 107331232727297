<template lang="pug">

.vc-common-mobile-category-list.menu-wrapper
  b-loading(:active="isLoading"
            :is-full-page="false")
  transition(enter-active-class="animated slideInLeft"
                   leave-active-class="animated slideOutLeft")
    ul.mobile-menu-items(v-if="!isSubCategoriesMenuShow")
      .usage-unit(v-for="usage in usages"
                  v-if="usages.length > 0"
                  :key="usage.id")
        .label(v-if="showUsages") {{ usage.name }}
        li.mobile-menu-item(v-for="category in childrenOf(usage)"
                            :key="category.id")
          a.link(href="#"
                @click.prevent="categoryClickedHandler(category)")
            span.text {{ category.name }}
            .icon(v-if="!category.is_leaf()")
              i.fa.fa-chevron-right
  transition(enter-active-class="animated slideInRight"
             leave-active-class="animated slideOutRight")
    ul.mobile-menu-items(v-if="isSubCategoriesMenuShow")
      li.mobile-menu-item
        a.link.selected-top-level-category(:href="`/${categoryType}s?category_id=${selectedCategory.id}`")
          span.text {{ selectedCategory.name }}
      li.mobile-menu-item(v-for="subCategory in childrenOf(selectedCategory)"
                          :key="subCategory.id")
        a.link(:href="`/${categoryType}s?category_id=${subCategory.id}`")
          span.text {{ subCategory.name }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    showUsages: {
      type: Boolean,
      default: () => {
        return true
      },
    },
  },

  data() {
    return {
      rootCategory: undefined,
    }
  },

  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    categoryType() {
      return this.mobileMenuStates.activatedCategoryType
    },

    isSubCategoriesMenuShow() {
      return (
        this.mobileMenuStates['isCategoriesSelectorActive'] &&
        this.mobileMenuStates['selectedTopLevelCategoryId'] !== null
      )
    },

    allCategories() {
      let rootCategory = this.rootCategory

      if (!rootCategory) return []

      return this.$store.getters['categorySystems/allCategories']().filter(
        (category) => category.lft >= rootCategory.lft && category.rgt <= rootCategory.rgt
      )
    },

    // 可用的「用途」選項
    usages() {
      return this.allCategories
        .filter((category) => category.depth === 1)
        .filter((category) => category.default_usage !== 'retail')
    },

    selectedCategory() {
      return this.$store.getters['categorySystems/findCategory'](this.mobileMenuStates['selectedTopLevelCategoryId'])
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },
  },
  // created() {},
  mounted() {
    if (this.usages.length > 0) return

    this.$store
      .dispatch(`categorySystems/fetchCategoriesByType`, {
        type: this.categoryType,
        sort: 'created_at',
        noReplace: true,
      })
      .then((response) => {
        let rootCategoryId = response.data.data.find((categoryRawData) => categoryRawData.attributes.depth === 0).id
        this.rootCategory = this.$store.getters['categorySystems/findCategory'](rootCategoryId)
      })
  },

  methods: {
    childrenOf(category) {
      return this.$store.getters['categorySystems/childrenOf'](category).sort((a, b) => a.position - b.position)
    },

    categoryClickedHandler(category) {
      if (category.is_leaf()) {
        this.$store
          .dispatch('toggleMobileMenu', Object.assign(this.mobileMenuStates, { isMobileMenuActive: false }))
          .then((_) => Turbolinks.visit(`/${this.categoryType}s?category_id=${category.id}`))
      } else {
        this.$store.dispatch(
          'toggleMobileMenu',
          Object.assign(this.mobileMenuStates, { selectedTopLevelCategoryId: category.id })
        )
      }
    },
  },
}
</script>
