import ProductImage from '@models/product_image'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new ProductImage(state.entities[id]))
}

export const forProduct = (state) => (id) => {
  return all(state).filter((image) => String(image.product_id) === id)
}

export const find = (state) => (id) => {
  return new ProductImage(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
