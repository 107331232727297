<template lang="pug">

.vc-order-cart-confirmation.odd-container.has-padding
  b-loading(:active="isLoading")

  grouped-items-unit(v-for="productType in productTypeList"
                     :key="productType"
                     :product-type="productType"
                     :order="order"
                     :cart-items="groupedItems[productType]"
                     :current-step="currentStep"
                     :cart-service="cartService")

  .form-container
    .form-column.-clean
      .form-section
        promo-code-checker(:order="order")

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(@click="confirmItems"
                                           :class="{ 'is-loading': isLoading }")
      | {{ actionLocaleText('next_step_is', { content: copyLocaleText('address_and_payment_method') }) }}

</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import GroupedItemsUnit from './grouped-items-unit.vue'
import PromoCodeChecker from './promo-code-checker.vue'
import BackToStoreButton from './back-to-store-button.vue'

export default {
  components: {
    GroupedItemsUnit,
    PromoCodeChecker,
    BackToStoreButton,
  },

  mixins: [checkoutFlowMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  mounted() {
    this._trackCheckoutProgress()
  },

  methods: {
    backToStore() {
      this.$localforage.getItem('redirect-path').then((path) => {
        Turbolinks.visit(path || '/')
      })
    },

    confirmItems() {
      if (this.order.order_state === 'was_split') {
        return this.$emit('update:currentStep', 2).then((_) => {
          this._scrollToTop()
        })
      }
      if (this.order.order_state === 'cart') {
        this.cartService
          .confirmItems()
          .then(() => {
            this._scrollToTop()
          })
          .catch((_) => {
            this._scrollToTop()
          })
      } else {
        this.cartService
          .confirmItems()
          .then(() => {
            return this.$emit('update:currentStep', 2)
          })
          .then(() => {
            this._scrollToTop()
          })
      }
    },
  },
}
</script>
