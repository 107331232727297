import DistributionBase from '@models/distribution_base.js'
import DistributionBaseLocation from '@models/distribution_base_location.js'
import DistributionBaseBrandShip from '@models/distribution_base_brand_ship.js'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new DistributionBase(state.entities[id]))
}

export const allLocations = (state) => {
  return state.locationResult.map((id) => new DistributionBaseLocation(state.locationEntities[id]))
}

export const allBrandShips = (state) => {
  return state.brandShipResult.map((id) => new DistributionBaseBrandShip(state.brandShipEntities[id]))
}

export const find = (state) => (id) => {
  return new DistributionBase(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
