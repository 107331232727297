import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'
import users from '@storeModules/users'
import userCollections from '@storeModules/user_collections'
import userCommonAddresses from '@storeModules/user_common_addresses'
import brands from '@storeModules/brands'
import brandBanners from '@storeModules/brand_banners'
import distributionBases from '@storeModules/distribution_bases'
import salesEvents from '@storeModules/sales_events'
import categorySystems from '@storeModules/category_systems'
import contents from '@storeModules/contents'
import products from '@storeModules/products'
import productTags from '@storeModules/product_tags'
import productOptionTypes from '@storeModules/product_option_types'
import productOptionValues from '@storeModules/product_option_values'
import productVariants from '@storeModules/product_variants'
import productImages from '@storeModules/product_images'
import productSupportDocuments from '@storeModules/product_support_documents'
import shippingMethods from '@storeModules/shipping_methods' // TODO: [Deprecated]
import paymentMethods from '@storeModules/payment_methods'
import banners from '@storeModules/banners'
import orders from '@storeModules/orders'
import orderItems from '@storeModules/order_items'
import orderShipments from '@storeModules/order_shipments'
import orderPayments from '@storeModules/order_payments'
import returnAuthorizations from '@storeModules/return_authorizations'
import reimbursements from '@storeModules/reimbursements'
import comments from '@storeModules/comments'
import infoLinks from '@storeModules/info_links'
import siteConfigs from '@storeModules/site_configs'
import CurrentUserAssignService from '@services/current_user_assign_service.js'

Vue.use(Vuex)

const state = {
  currentUser: CurrentUserAssignService.call('user'),
  flashMessages: [],
  queryString: {},
  retryCounter: 0,
  device: {
    isMobile: false,
    deviceType: '',
    windowWidth: 0,
  },
  mobileMenuStates: {
    isMobileMenuActive: false,
    activatedCategoryType: '',
    isCategoriesSelectorActive: false,
    selectedTopLevelCategoryId: null,
    isSkippingRootMenuToClose: false,
  },
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    users,
    userCollections,
    userCommonAddresses,
    brands,
    brandBanners,
    distributionBases,
    salesEvents,
    categorySystems,
    contents,
    products,
    productTags,
    productOptionTypes,
    productOptionValues,
    productVariants,
    productImages,
    productSupportDocuments,
    shippingMethods, // TODO: [Deprecated]
    paymentMethods,
    banners,
    orders,
    orderShipments,
    orderPayments,
    returnAuthorizations,
    reimbursements,
    comments,
    orderItems,
    infoLinks,
    siteConfigs,
  },
})
