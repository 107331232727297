import CategoryMenu from '@applicationComponents/category/sidebar-category-menu.vue'
import SlideshowContainer from '@applicationComponents/common/slideshow-container.vue'
import ListTable from '@applicationComponents/product/list-table.vue'
export default {
  name: 'products-index-page-container',

  components: {
    CategoryMenu,
    SlideshowContainer,
    ListTable,
  },
  // mixins: [],
  // props: {},
  data() {
    return {
      selectedCategory: undefined,
      categoryMenuIsReady: false,
    }
  },

  computed: {
    impressionLocation() {
      if (!this.selectedCategory) return

      return `Category: ${this.selectedCategory.name_with_parents}`
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
