const STEP_MAP = {
  cart: 1,
  items_confirmed: 2,
  confirmed: 3,
  was_split: 3,
  completed: 3,
}

export default class CheckoutStepsPArseService {
  constructor(order) {
    this.order = order
  }

  call() {
    return STEP_MAP[this.order.order_state]
  }

  static call(order) {
    return new this(order).call()
  }
}
