import ShippingMethod from '@models/shipping_method'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new ShippingMethod(state.entities[id]))
}

export const rateTypes = (state) => {
  return state.rateTypes
}

export const gateways = (state) => {
  return state.gateways
}

export const find = (state) => (id) => {
  return new ShippingMethod(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
