<template lang="pug">

.vc-order-shipment-item-unit
  .group
    .group.option-and-thumb
      .checkbox-wrapper(v-if="isEditable")
        b-checkbox(v-model="isItemSelected"
                   type="is-warning"
                   @input="emitEvent"
                   :disabled="!rmaApplicable")
      .product-thumb
        img.img(:src="variantThumb")
    .group.name-and-unit-price
      .product-name
        .name {{ variant.product_name }}
        .variant(v-if="variant.name") {{ variant.name }}
        .state-tag
          span.tag(:class="stateType") {{ aasmStateLocaleText('order/shipment_item', 'state', item.state) }}
          span.tag.is-warning(v-if="item.is_rma_pending") {{ attributeLocaleText('order/shipment_item', 'is_rma_pending') }}
          span.tag.is-warning(v-if="isOverAppreciationPeriod") {{ attributeLocaleText('order/shipment_item', 'is_over_appreciation_period') }}
      .unit-price(v-if="showPrice")
        label.label {{ attributeLocaleText('order/item', 'price') }}
        .price(:class="{ gift: isGiftItem }")
          span {{ toMoney(orderItem.price).format() }}
          span.gift-tag(v-if="isGiftItem") {{ copyLocaleText('gift') }}
  .group
    .quantity-config
      .returnable-quantity(:class="{ '-editable': isEditable && !isReturnedOrPending }")
        label.label {{ quantityPickerLabel }}
        span.quantity(v-if="isEditable && !isReturnedOrPending") {{ item.returnable_quantity }}
      quantity-picker(:quantity.sync="quantity"
                      :current-stock="item.returnable_quantity"
                      :pcs-per-package="1"
                      :is-in-shipment-item="true"
                      :is-confirmed="!rmaApplicable || !isEditable")
      .returnable-quantity(v-if="!isEditable && !isReturnedOrPending")
        label.label {{ attributeLocaleText('order/shipment_item', 'returnable_quantity') }}
        span.quantity &nbsp;{{ item.returnable_quantity }}

</template>

<script>
import moment from 'moment'
import QuantityPicker from '../product/quantity-picker.vue'

export default {
  components: {
    QuantityPicker,
  },
  // mixins: [],
  props: {
    item: {
      type: Object,
      required: true,
    },

    form: {
      type: Object,
      required: false,
    },

    showPrice: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      isItemSelected: false,
      quantity: 0,
    }
  },

  computed: {
    rmaApplicable() {
      return this.item.state !== 'returned' && !this.item.is_rma_pending && !this.isOverAppreciationPeriod
    },

    isEditable() {
      // if this.form is an object that pass in via props
      return !!this.form
    },

    isOverAppreciationPeriod() {
      if (!this.item.appreciation_period) return false

      return moment().isAfter(
        moment(this.item.delivered_at, 'X').add(this.item.appreciation_period, 'days').endOf('day')
      )
    },

    isReturnedOrPending() {
      return this.item.is_rma_pending || this.item.state === 'returned'
    },

    stateType() {
      return {
        'is-info': this.item.state === 'on_hand',
        'is-warning': this.item.state === 'backordered',
        'is-success': ['shipped', 'delivered'].includes(this.item.state),
        'is-danger': this.item.state === 'returned',
      }
    },

    orderItem() {
      return this.$store.getters['orderItems/find'](this.item.item_id)
    },

    variant() {
      return this.$store.getters['productVariants/find'](this.item.variant_id)
    },

    variantThumb() {
      if (!this.variant.cover) return 'https://via.placeholder.com/128x128?text=No+Image'

      return this.variant.cover.thumb.url
    },

    returnItemObject() {
      let result = {
        shipment_item_id: this.item.id,
        name: `${this.variant.product_name}`,
        image: this.variantThumb,
        return_quantity: this.quantity,
        unit_price: this.toMoney(this.orderItem.price).amount,
        reimbursement_type: 'refund',
        description: '',
        images: [],
      }

      if (this.variant.name) result['name'] += ` - ${this.variant.name}`

      return result
    },

    isGiftItem() {
      return this.orderItem.use_type === 'gift'
    },

    quantityPickerLabel() {
      if (this.isEditable) {
        if (this.isReturnedOrPending) return this.attributeLocaleText('return_authorization/item', 'return_quantity')
        return this.attributeLocaleText('order/shipment_item', 'returnable_quantity')
      } else {
        if (this.isReturnedOrPending) return this.attributeLocaleText('return_authorization/item', 'return_quantity')
        return this.attributeLocaleText('order/item', 'quantity')
      }
    },
  },

  watch: {
    quantity: {
      handler(newValue) {
        if (!this.isEditable) return

        let returnItem = this.form.items.find((item) => item.shipment_item_id === this.item.id)

        if (returnItem) returnItem.return_quantity = newValue
      },
    },
  },

  // created() {},
  mounted() {
    if (this.isEditable) {
      if (this.isReturnedOrPending) {
        this.quantity = this.item.returnable_quantity
      } else {
        this.quantity = 1
      }

      this.isItemSelected = !!this.form.items.find((item) => item.shipment_item_id === this.item.id)
    } else {
      if (this.isReturnedOrPending) {
        this.quantity = this.item.returnable_quantity
      } else {
        let result = this.item.returnable_quantity / this.variant.pcs_per_package

        if (result % 1 !== 0) result = parseFloat(result.toFixed(2))

        this.quantity = result
      }
    }
  },

  methods: {
    changeCheckboxValueTo(boolean) {
      if (this.isItemSelected === boolean) return

      this.isItemSelected = boolean
      this.emitEvent()
    },

    emitEvent() {
      if (this.isItemSelected) {
        this.addItem()
      } else {
        this.removeItem()
      }
    },

    addItem() {
      this.form.items.push(this.returnItemObject)
    },

    removeItem() {
      const index = this.form.items.findIndex((item) => item.shipment_item_id === this.item.id)

      this.form.items.splice(index, 1)
    },
  },
}
</script>
