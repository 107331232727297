<template lang="pug">

.vc-dashboard-user-transaction-list
  h2.title {{ modelNameLocaleText('user/transaction') }}
  b-table(:data="transactions"
          :default-sort="sortField"
          :default-sort-direction="sortOrder"
          :loading="isLoading"
          :hoverable="true"
          :row-class="(row, index) => isLatestRecord(row) && 'is-latest'")

    template(slot-scope="props")

      b-table-column(field="created_at"
                     :label="attributeLocaleText('user/transaction', 'created_at')"
                     width="150")
        span(:title="dateTimeLocaleText(props.row.created_at, 'HH:mm:ss')") {{ dateTimeLocaleText(props.row.created_at, 'YYYY-MM-DD') }}
      b-table-column(field="reason"
                     :label="attributeLocaleText('user/transaction', 'reason')"
                     width="150")
        span.reason.tag.is-default {{ enumLocaleText('user/transaction', 'reason', props.row.reason) }}
      b-table-column(field="notes"
                     :label="attributeLocaleText('user/transaction', 'notes')")
        .content(v-for="note in props.row.notes"
                 v-html="simpleFormat(note)")
      b-table-column(field="amount"
                     :label="attributeLocaleText('user/transaction', 'amount')"
                     numbric
                     width="150")
        span.amount(:class="amountType(toMoney(props.row.amount))") {{ toMoney(props.row.amount).format() }}
      b-table-column(field="balance"
                     :label="attributeLocaleText('user/transaction', 'balance')"
                     numbric
                     width="150")
        span.balance {{ toMoney(props.row.balance).format() }}

  odd-pagination(:total="totalCount"
                 :current.sync="currentPage"
                 :per-page="pageSize"
                 size="is-small"
                 order="is-centered"
                 :rounded="true"
                 @change="onPageChange")

</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '@sharedMixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OddPagination from '@applicationComponents/common/odd-pagination.vue'

export default {
  components: {
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      resourceType: 'users',
      pageSize: 20,
      sortOrder: 'desc',
      sortField: 'created_at',
      currentPage: 1,
    }
  },

  computed: {
    transactions() {
      return this.$store.getters['users/allTransactions'].sort((a, b) => a.created_at - b.created_at)
    },

    listContainerOffsetTop() {},
  },

  created() {
    this.fetchingInitialData()
  },
  // mounted() {},

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('users/fetchTransactions', { model: this.user, options })
    },

    updateQueryString() {}, // 不更新 queryString

    isLatestRecord(row) {
      if (this.currentPage !== 1) return false

      return row === this.transactions[this.transactions.length - 1]
    },

    amountType(amount) {
      return {
        'is-positive': amount.isPositive(),
        'is-negative': amount.isNegative(),
      }
    },

    userTransactionAddedHandler() {
      this.$emit('user-transaction-added')
      this.reloadData()
    },
  },
}
</script>
