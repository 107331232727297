<template lang="pug">

.vc-order-addresses-and-extra-info
  .info-column.left
    address-info-unit(:order="order"
                      address-type="billing")
    address-info-unit(:order="order"
                      address-type="shipping")

  .info-column.right
    .info-section(v-if="order.notes")
      .section-label
        span.text {{ attributeLocaleText('order', 'notes') }}
      .section-content
        .order-notes(v-html="simpleFormat(order.notes)")

    .info-section
      .section-label
        span.text {{ copyLocaleText('checkout_flow.checkout_detail') }}
      calculated-price-detail(v-if="showPriceDetail"
                              :order="order"
                              :cart-items="$store.getters['orderItems/all']"
                              :product-type="order.product_type")
      .section-content(v-if="!showPriceDetail")
        .checkout-detail
          .label {{ copyLocaleText('checkout_flow.session_total_price') }}
          .price {{ toMoney(checkoutTotal).format() }}
      .section-content(v-if="adjustmentsFromUserTransactionsTotal < 0")
        .checkout-detail
          .label {{ copyLocaleText('deduct_amount_of_store_credit')}}
          .price {{ toMoney(Math.abs(adjustmentsFromUserTransactionsTotal)).format() }}

    .info-section(v-if="order.isWeeklyPaymentType")
      .weekly-payment-indicator
        .icon
          i.fa.fa-info-circle
        span {{ copyLocaleText('this_order_is_weekly_payment_type') }}

</template>

<script>
import AddressInfoUnit from './address-info-unit.vue'
import CalculatedPriceDetail from './calculated-price-detail.vue'

export default {
  components: {
    AddressInfoUnit,
    CalculatedPriceDetail,
  },

  // mixins: [checkoutFlowMixin],

  props: {
    order: {
      type: Object,
      required: true,
    },

    showPriceDetail: {
      type: Boolean,
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    checkoutTotal() {
      return this.$store.getters['orders/all']
        .filter((order) => order.order_state !== 'was_split')
        .map((order) => order.total)
        .reduce((sum, total) => sum + total, 0)
    },

    adjustments() {
      return this.$store.getters['orders/allAdjustments'].filter(
        (adjustment) => !this.order.gift_items.map((gift) => gift.id).includes(String(adjustment.adjustable_id))
      )
    },

    adjustmentsFromUserTransactionsTotal() {
      return this.adjustments
        .filter((adjustment) => {
          return adjustment.source_type === 'User::Transaction'
        })
        .reduce((prevValue, adjustment) => prevValue + adjustment.amount, 0)
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    methodIconClass(paymentType) {
      return {
        'fa-credit-card': paymentType === 'credit_card',
        'atm-icon': paymentType === 'vacc',
      }
    },
  },
}
</script>
