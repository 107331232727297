import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'users',
  attributes: [
    'created_at',
    'email',
    'id',
    'last_sign_in_at',
    'sign_in_count',
    'updated_at',
    'member_level',
    'comments',
    'profile',
    'name',
    'phone',
    'notes',
    'store_credit',
  ],
  editableAttributes: ['email', 'new_password', 'password', 'password_confirmation', 'profile'],
}

export default class User extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static check(email) {
    const requestBody = {
      data: {
        type: 'user-check',
        attributes: {
          email,
        },
      },
    }

    return axios.post(`${new this().apiBasePath()}/check`, requestBody)
  }

  static getCollections(latestUpdatedAt = 0) {
    return axios.get(`${new this().apiBasePath()}/collections?latest_updated_at=${latestUpdatedAt}`)
  }

  static fetchRecentOrders(options = {}) {
    return axios.get(`${new this().apiBasePath()}/recent_orders?${FetchingDataOptionsService.call(options)}`)
  }

  static fetchOrders(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.productType) queryString += `&product_type=${options.productType}`

    return axios.get(`${new this().apiBasePath()}/orders?${queryString}`)
  }

  static getOrder(token) {
    return axios.get(`${new this().apiBasePath()}/orders/${token}`)
  }

  static applyRma(orderToken, formData) {
    return axios.post(`${new this().apiBasePath()}/orders/${orderToken}/apply_rma`, formData)
  }

  static getGuestOrder(token, jwt) {
    return axios.get(`${new this().apiBasePath({ withResourceType: false })}/users/guest_orders/${token}?jwt=${jwt}`)
  }

  static currentOrder(options = {}) {
    if (options.noCache) {
      return axios.get(`${new this().apiBasePath()}/current_order?no_cache=true`)
    } else {
      return axios.get(`${new this().apiBasePath()}/current_order`)
    }
  }

  static currentOrderItems() {
    return axios.get(`${new this().apiBasePath()}/current_order/items`)
  }

  static addItemToCart(requestBody) {
    return axios.post(`${new this().apiBasePath()}/current_order/items`, requestBody)
  }

  static updateCartItem(cartItem) {
    return axios.put(`${new this().apiBasePath()}/current_order/items`, {
      data: {
        type: 'order_items',
        attributes: {
          order_item_id: cartItem.id,
          variant_id: cartItem.variant_id,
          quantity: cartItem.quantity,
        },
      },
    })
  }

  static removeCartItem(cartItem) {
    return axios.delete(`${new this().apiBasePath()}/current_order/items`, {
      data: {
        data: {
          type: 'order_items',
          attributes: {
            order_item_id: cartItem.id,
          },
        },
      },
    })
  }

  static fetchCommonAddresses() {
    return axios.get(`${new this().apiBasePath()}/common_addresses`)
  }

  static confirmItems() {
    const requestBody = {
      data: {
        type: 'confirm_order_items',
        attributes: {},
      },
    }

    return axios.post(`${new this().apiBasePath()}/current_order/confirm_items`, requestBody)
  }

  static placeOrder(order) {
    const requestBody = {
      data: {
        type: 'orders',
        attributes: {
          email: order.email,
          buyer_name: order.buyer_name,
          buyer_phone: order.buyer_phone,
          notes: order.notes,
          shipping_address: order.shipping_address,
          is_same_as_billing_address: order.is_same_as_billing_address,
          billing_address: order.billing_address,
          save_shipping_address_as_common: order.save_shipping_address_as_common,
          save_billing_address_as_common: order.save_billing_address_as_common,
          is_agree_with_term_of_service: order.is_agree_with_term_of_service,
          is_agree_to_receive_news_letters: order.is_agree_to_receive_news_letters,
          payment_method_id: order.payment_method_id,
        },
      },
    }
    return axios.post(`${new this().apiBasePath()}/current_order/place_order`, requestBody)
  }

  static applyCoupon(code) {
    return axios.post(`${new this().apiBasePath()}/current_order/apply_coupon`, {
      data: {
        type: 'apply_coupon',
        attributes: {
          coupon_code: code,
        },
      },
    })
  }

  static removeCoupon(code) {
    return axios.delete(`${new this().apiBasePath()}/current_order/apply_coupon`, {
      data: {
        data: {
          type: 'remove_coupon',
          attributes: {
            coupon_code: code,
            is_remove: true,
          },
        },
      },
    })
  }

  static fetchReturnAuthorizations(options = {}) {
    return axios.get(`${new this().apiBasePath()}/return_authorizations?${FetchingDataOptionsService.call(options)}`)
  }

  static getReturnAuthorization(number) {
    return axios.get(`${new this().apiBasePath()}/return_authorizations/${number}`)
  }

  static importUsers(formData) {
    return axios.post(`${new this().apiBasePath()}/import`, formData)
  }

  updateProfile() {
    const body = {
      id: this.id,
      data: {
        type: 'user_profiles',
        attributes: this.profile,
      },
    }
    return axios.put(`${this.apiBasePath()}/profile`, body)
  }

  fetchNotes(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/notes?${FetchingDataOptionsService.call(options)}`)
  }

  addNote(note) {
    return axios.post(`${this.apiBasePath()}/${this.id}/notes`, note.requestBody())
  }

  fetchTransactions(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/transactions?${FetchingDataOptionsService.call(options)}`)
  }

  addTransaction(transaction) {
    return axios.post(`${this.apiBasePath()}/${this.id}/transactions`, transaction.requestBody())
  }

  toggleArrearFlag() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_arrear_flag`)
  }

  toggleSuspended() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_suspended`)
  }
  // extra methods or helpers here...
  get fetchNotesActionName() {
    return 'users/fetchNotes'
  }

  get addNoteActionName() {
    return 'users/addNote'
  }
}
