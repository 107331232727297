import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_items',
  attributes: [
    'id',
    'price',
    'quantity',
    'created_at',
    'variant',
    'variant_id',
    'order',
    'adjustment_total',
    'promotion_total',
    'use_type',
    'pre_tax_total',
    'tax_amount',
    'post_tax_total',
    'product_type',
    'pcs_per_package',
    'event_price_id',
  ],
  editableAttributes: ['quantity', 'variant_id'],
}

export default class OrderItem extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...

  get subTotal() {
    return this.price * this.quantity + this.adjustment_total
  }

  get isPreorderItem() {
    return !!this.event_price_id
  }
}
