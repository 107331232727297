import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'return_authorizations',
  attributes: [
    'id',
    'number',
    'state',
    'note',
    'reason',
    'order',
    'order_id',
    'items',
    'reimbursements',
    'is_reimbursed',
    'total_amount',
    'created_at',
    'updated_at',
    'store_name',
    'customer_code',
    'comments',
  ],
  editableAttributes: ['note', 'reason_id', 'items', 'order_token'],
}

export default class ReturnAuthorization extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.productType) queryString += `&product_type=${options.productType}`

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  accept() {
    return axios.put(`${this.apiBasePath()}/${this.id}/accept`)
  }

  cancel() {
    return axios.put(`${this.apiBasePath()}/${this.id}/cancel`)
  }

  createReimbursement() {
    return axios.post(`${this.apiBasePath()}/${this.id}/create_reimbursement`)
  }

  fetchComments(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/comments?${FetchingDataOptionsService.call(options)}`)
  }

  addComment(content) {
    const requestBody = {
      data: {
        type: 'add_comment',
        attributes: {
          content,
        },
      },
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/comments`, requestBody)
  }

  // extra methods or helpers here...
  get hasReimbursements() {
    return this.reimbursements.length > 0
  }

  stateType() {
    return {
      'is-warning': this.state === 'pending',
      'is-success': this.state === 'authorized',
      'is-danger': this.state === 'canceled',
    }
  }
}
