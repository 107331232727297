<template lang="pug">

.vc-order-cart-item-list
  .order-items-container
    .list-header
      .label-part.main
        .label
          .icon
            i.fa.fa-list
          span {{ copyLocaleText('order_info') }}
      .label-part.info.unit-price
        .label
          span {{ attributeLocaleText('order/item', 'price') }}
      .label-part.info.quantity
        .label
          span {{ copyLocaleText('purchase_quantity') }}
      .label-part.info.price
        .label
          span {{ copyLocaleText('price') }}

    cart-item(v-for="(cartItem, index) in cartItems"
              :key="cartItem.id"
              :index="index"
              :cart-item="cartItem"
              :cart-service="cartService"
              :is-confirmed="isConfirmed")

</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItem from './cart-item.vue'

export default {
  components: {
    CartItem,
  },

  mixins: [checkoutFlowMixin],

  props: {
    currentStep: {
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isConfirmed() {
      if (this.currentStep && this.currentStep === 1) return false
      return ['confirmed', 'completed', 'canceled', 'was_split'].includes(this.order.order_state)
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
