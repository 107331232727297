import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'payment_methods',
  attributes: ['id', 'name', 'description', 'gateway_type', 'payment_type', 'enabled', 'is_default'],
  editableAttributes: ['name', 'description', 'gateway_type', 'payment_type'],
}

export default class PaymentMethod extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchGateways() {
    return axios.get(`${new this().apiBasePath({ withResourceType: false })}/payment_gateways`)
  }

  static configGateway(gateway) {
    let requestBody = {
      data: {
        type: 'gateway_config',
        attributes: {
          provider: gateway.provider,
        },
      },
    }

    gateway.required_fields.forEach((field) => (requestBody.data.attributes[field] = gateway[field]))

    return axios.put(
      `${new this().apiBasePath({ withResourceType: false })}/payment_gateways/${gateway.provider}`,
      requestBody
    )
  }

  static removeGatewayConfigurations(gateway) {
    return axios.delete(`${new this().apiBasePath({ withResourceType: false })}/payment_gateways/${gateway.provider}`)
  }

  static fetchInvoiceServices() {
    return axios.get(`${new this().apiBasePath({ withResourceType: false })}/invoice_services`)
  }

  static configInvoiceService(service) {
    let requestBody = {
      data: {
        type: 'invoice_service_config',
        attributes: {
          provider: service.provider,
        },
      },
    }

    service.required_fields.forEach((field) => (requestBody.data.attributes[field] = service[field]))

    return axios.put(
      `${new this().apiBasePath({ withResourceType: false })}/invoice_services/${service.provider}`,
      requestBody
    )
  }

  static removeInvoiceServiceConfigurations(service) {
    return axios.delete(`${new this().apiBasePath({ withResourceType: false })}/invoice_services/${service.provider}`)
  }

  static fetchInvoiceForPaymentConfig() {
    return axios.get(
      `${new this().apiBasePath({ withResourceType: false })}/invoice_services/invoice_for_payment_config`
    )
  }

  static toggleInvoiceForPaymentConfig() {
    return axios.put(
      `${new this().apiBasePath({ withResourceType: false })}/invoice_services/invoice_for_payment_config`
    )
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }
  // extra methods or helpers here...
}
