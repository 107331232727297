import ShoppingCartService from '@services/shopping_cart_service.js'
import OrderDetailContent from '@applicationComponents/order/detail-content.vue'

export default {
  name: 'user-order-show-container',

  components: {
    OrderDetailContent,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      cartService: undefined,
      orderToken: undefined,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    isOrderLoaded() {
      return typeof this.order.id === 'string'
    },

    order() {
      return this.$store.getters['orders/find'](this.orderToken)
    },

    cartItems() {
      if (this.order.items) {
        return this.order.items.map((item) => this.$store.getters['orderItems/find'](item.id))
      } else {
        return []
      }
    },

    giftItems() {
      if (this.order.gift_items) {
        return this.order.gift_items.map((item) => this.$store.getters['orderItems/find'](item.id))
      } else {
        return []
      }
    },
  },

  created() {
    this.cartService = new ShoppingCartService(this.$localforage, this.$store)
  },

  mounted() {
    this.orderToken = this.$el.dataset.order
    this.$store.dispatch('users/getOrder', this.orderToken)
  },

  // methods: {}
}
