import ProductCollectService from '@services/product_collect_service.js'
import JwtManageService from '@services/jwt_manage_service.js'
import FlashMessage from '@applicationComponents/common/flash-message.vue'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: 'basic-initializer',

  components: {
    FlashMessage,
  },

  data() {
    return {
      autoRefresher: null,
    }
  },

  computed: {
    ...mapGetters(['flashMessages', 'currentUser']),

    currentCollections() {
      return this.$store.getters['userCollections/currentCollections']
    },

    collectionsLatestUpdatedAt() {
      return this.$store.getters['userCollections/latestUpdatedAt']
    },
  },

  beforeMount() {
    const IPHONE_SE_WIDTH = 320
    const IPHONE_PLUS_WIDTH = 414
    const IPAD_PRO_WIDTH = 1024
    const LEEWAY = 1
    const handler = () => {
      if (!document.hidden) {
        let windowWidth = window.innerWidth
        let isMobile = windowWidth < IPAD_PRO_WIDTH + LEEWAY
        let deviceType = ''

        if (isMobile && windowWidth > IPHONE_SE_WIDTH) {
          deviceType = windowWidth > IPHONE_PLUS_WIDTH ? 'tablet' : 'iphone'
        } else {
          deviceType = isMobile ? 'iphone-se' : 'desktop'
        }

        this.toggleDevice({
          isMobile,
          deviceType,
          windowWidth,
        })
      }
    }

    window.addEventListener('resize', debounce(handler, 200))
    handler()
  },

  mounted() {
    const flashMessages = JSON.parse(this.$el.dataset.flashMessages)

    this.$store.dispatch('setFlashMessages', flashMessages)
    this.initializeProductCollections()
    this._setupAutoRefresher()
  },

  destroyed() {
    clearInterval(this.autoRefresher)
  },

  methods: {
    toggleDevice(device) {
      this.$store.dispatch('toggleDevice', device)
    },

    collectedProducts() {
      return this.$localforage.getItem(ProductCollectService.collectionKey())
    },

    initializeProductCollections() {
      new ProductCollectService(this.currentUser.type, this.currentCollections, this.$localforage, this.$store)
        .initialize()
        .then(() => {
          this.$localforage.setItem(ProductCollectService.collectionKey(), this.currentCollections)
          this.$localforage.setItem(
            ProductCollectService.collectionLatestUpdatedAtKey(),
            this.collectionsLatestUpdatedAt
          )
        })
    },

    _setupAutoRefresher(interval = 3540) {
      JwtManageService.autoRefreshJwt('user', interval)
      this.autoRefresher = setInterval(() => {
        JwtManageService.autoRefreshJwt('user', interval)
      }, interval * 1000)
    },
  },
}
