<template lang="pug">

.vc-return-authorization-comment-form.clearfix
  b-input(type="textarea"
          :placeholder="messageLocaleText('help.leave_your_comment_here')"
          v-model="message"
          rows="2")

  b-field.image-upload-block
    b-upload(v-model="images"
              multiple
              drag-drop
              accept="image/*")
      .section
        .content.has-text-centered
          b-icon(icon="upload"
                  size="is-medium")
          p {{ messageLocaleText('help.drop_your_files_here_or_click_to_upload') }}
    .image-list
      .image-unit(v-for="(image, index) in images")
        .image(:style="`background-image: url(${readFile(image)})`")
          .remove-button(@click="deleteFileFrom(images, index)")
            i.fa.fa-close

  hr

  .is-pulled-right
    button.button.is-primary(@click="submit"
                       :class="{'is-loading': isUploading}") {{ actionLocaleText('submit') }}


</template>

<script>
import Comment from '@models/comment.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      message: '',
      images: [],
      isUploading: false,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['returnAuthorizations/isLoading']
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    submit() {
      this.isUploading = true
      this.$store
        .dispatch('returnAuthorizations/addComment', {
          model: this.returnAuthorization,
          content: this.message,
        })
        .then((response) => {
          if (this.images.length < 1) return

          const comment = new Comment({ id: response.data.data.id })

          return this.$store.dispatch('comments/addAttachments', {
            model: comment,
            formData: this._buildAttachmentFormData(),
          })
        })
        .then((_) => {
          this.message = ''
          this.images = []
          this.isUploading = false
        })
        .then((_) => {
          this.$parent.close()
        })
    },

    readFile(file) {
      return URL.createObjectURL(file)
    },

    deleteFileFrom(imagesArray, index) {
      imagesArray.splice(index, 1)
    },

    _buildAttachmentFormData() {
      let formData = new FormData()

      this.images.forEach((image) => {
        formData.append('attachment[files][]', image)
      })

      return formData
    },
  },
}
</script>
