<template lang="pug">

.vc-dashboard-order-filters
  //- link mode
  template(v-if="interactionMode === 'link'")
    a.filter-unit(v-for="filter in availableFilters"
                  :href="filter.path")
      .icon
        i.fa(:class="filter.icon")
      .text {{ copyLocaleText(`order_filters.${filter.name}`) }}
  //- filter mode
  template(v-else)
    a.filter-unit.filter-mode(v-for="filter in availableFilters"
                              @click="filterOn(filter.name)"
                              :class="{ 'is-active': filter.name === currentFilter }")
      .icon
        i.fa(:class="filter.icon")
      .text {{ copyLocaleText(`order_filters.${filter.name}`) }}

  a.filter-unit(:class="{ 'filter-mode': interactionMode === 'filter' }"
                href="/user/return_authorizations")
      .icon
        i.fa(class="fa-file-text-o")
      .text {{ modelNameLocaleText('return_authorization') }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    interactionMode: {
      type: String,
      required: false,
      default: () => {
        return 'link'
      },
      validator: (value) => {
        return ['link', 'filter'].includes(value)
      },
    },

    currentFilter: {
      type: String,
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    availableFilters() {
      return [
        {
          name: 'completed',
          icon: 'fa-file-text',
          path: '/user/orders?filter=completed',
        },
        {
          name: 'waiting_for_shipment',
          icon: 'fa-gift',
          path: '/user/orders?filter=waiting_for_shipment',
        },
        {
          name: 'shipped',
          icon: 'fa-truck',
          path: '/user/orders?filter=shipped',
        },
        {
          name: 'delivered',
          icon: 'fa-home',
          path: '/user/orders?filter=delivered',
        },
        {
          name: 'canceled',
          icon: 'fa-trash',
          path: '/user/orders?filter=canceled',
        },
      ]
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    filterOn(name) {
      this.$emit('order-filter-updated', name)
    },
  },
}
</script>
