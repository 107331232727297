<template lang="pug">

.vc-order-price-detail
  ul.wrapper
    li.detail-unit
      .inner(:data-minus="false")
        .label {{ `${attributeLocaleText('order', 'item_total')}(${copyLocaleText('pre_tax')})` }}
        .price(data-currency="TWD"
              :data-empty="order.item_total === 0") {{ toMoney(order.item_total).format() }}
      ul
        li.inner.sub(:data-minus="false")
          .label Tax
          .price(data-currency="TWD") {{ computeTax(toMoney(order.item_total)).taxAmount.format() }}
        li.inner.sub(v-for="adjustment in adjustmentsToItem"
                    :key="adjustment.id"
                    :data-minus="adjustment.amount < 0")
          .label {{ adjustment.title }}
          .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}
    li.detail-unit
      .inner(:data-minus="false")
        .label {{ attributeLocaleText('order', 'shipment_total') }}
        .price(data-currency="TWD"
              :data-empty="order.shipment_total === 0") {{ shipmentTotal }}
      ul
        li.inner.sub(v-for="adjustment in adjustmentsToShipment"
                    :key="adjustment.id"
                    :data-minus="adjustment.amount < 0")
          .label {{ adjustment.title }}
          .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}
    li.detail-unit
      .inner(v-for="adjustment in adjustmentsToOrder"
             :key="adjustment.id"
             :data-minus="adjustment.amount < 0")
        .label {{ adjustment.title }}
        .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}

    .order-total
      .label
        span {{ attributeLocaleText('order', 'total') }}
        br
        span.tax-note {{ attributeLocaleText('order', 'include_business_tax', { tax: toMoney(order.tax_total).format() }) }}
      .price(data-currency="TWD") {{ toMoney(order.total).format() }}

  //- .notice-message-unit
    .icon
      i.fa.fa-info-circle
    span 訂單達 $400 以上即可享免運費優惠。

</template>

<script>
import priceDetailMixin from '../mixins/price_detail_mixin.js'

export default {
  // components: {},
  mixins: [priceDetailMixin],

  // props: {}
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
