import backendPaginateFilterSortAndSearchableMixin from '@sharedMixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OrderFilters from '@applicationComponents/dashboard/order-filters.vue'
import OrderSummaryUnit from '@applicationComponents/order/summary-unit.vue'
import ShoppingCartService from '@services/shopping_cart_service.js'
import OddPagination from '@applicationComponents/common/odd-pagination.vue'

const PRODUCT_TYPES = ['regular', 'retail', 'healthy']

export default {
  name: 'user-order-list-container',

  components: {
    OrderFilters,
    OrderSummaryUnit,
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'orders',
      currentPage: 1,
      pageSize: 20,
      sortOrder: 'desc',
      sortField: 'completed_at',
      currentFilter: 0,
      availableFilters: [
        'completed',
        // 'waiting_for_payment',
        'waiting_for_shipment',
        'shipped',
        'delivered',
        'canceled',
        'waiting_for_refund',
      ],
      isSearchOptionsOpen: false,
      searchOptions: {},
      cartService: new ShoppingCartService(this.$localforage, this.$store),
      orderIds: [],
      productTypes: PRODUCT_TYPES,
      currentProductType: 0,
    }
  },

  computed: {
    additionalOptions() {
      return {
        productType: this.productTypes[this.currentProductType],
      }
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    orders() {
      return this.$store.getters['orders/all'].filter((order) => this.orderIds.includes(order.id))
    },

    hasOrders() {
      return this.orders.length > 0
    },
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    checkAdditionalOptionsFromUrl() {
      return {
        productType: this.currentQueryString['product_type'] || this.productTypes[this.currentProductType],
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      if (options.productType) this.currentProductType = this.productTypes.indexOf(options.productType)
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      result += `&product_type=${options.productType}`

      return result
    },

    fetchData(options) {
      this.currentPage = options.pageNumber
      this.$store.dispatch(`users/fetchOrders`, options).then((response) => {
        this.orderIds = response.data.data.map((order) => order.id)
      })
    },

    filterUpdatedHandler(filterName) {
      const indexOfUpdatedFilter = this.availableFilters.indexOf(filterName)

      this.currentFilter = indexOfUpdatedFilter
      this.currentPage = 1
      this.filterOnChangeHandler(indexOfUpdatedFilter)
    },

    productTypeOnChangeHandler() {
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
    },
  },
}
