<template lang="pug">

.vc-product-quantity-picker(:class="{ disabled: isConfirmed, 'animated headShake': isQuantityInvalid }")
  .label {{ actionLocaleText('set_quantity') }}

  .inner-wrapper
    .picker-unit(:class="{ '-need-update': isQuantityInvalid }")
      .option-button.minus(@click="onClick($event, decreaseQuantity)"
                          @mousedown="onStartLongPress($event, decreaseQuantity)"
                          @touchstart.prevent="onStartLongPress($event, decreaseQuantity)"
                          @mouseup="onStopLongPress($event, decreaseQuantity)"
                          @mouseleave="onStopLongPress($event, decreaseQuantity)"
                          @touchend="onStopLongPress($event, decreaseQuantity)"
                          @touchcancel="onStopLongPress($event, decreaseQuantity)")
        .icon
          i.fa.fa-minus
      .quantity-display
        span.number {{ quantity }}
      .option-button.plus(@click="onClick($event, increaseQuantity)"
                          @mousedown="onStartLongPress($event, increaseQuantity)"
                          @touchstart.prevent="onStartLongPress($event, increaseQuantity)"
                          @mouseup="onStopLongPress($event, increaseQuantity)"
                          @mouseleave="onStopLongPress($event, increaseQuantity)"
                          @touchend="onStopLongPress($event, increaseQuantity)"
                          @touchcancel="onStopLongPress($event, increaseQuantity)")
        .icon
          i.fa.fa-plus

    .purchaseable-quantity(v-if="!isConfirmed"
                           :class="{ '-need-update': isQuantityInvalid }")
      template(v-if="isCheckingStock")
        span.unit-label {{ attributeLocaleText('order/item', 'purchasable_quantity') }}
        span.has-text-weight-bold {{ maxPurchaseableQuantity }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    quantity: {
      type: Number,
      required: true,
    },

    currentStock: {
      type: Number,
      required: true,
    },

    pcsPerPackage: {
      type: Number,
      required: true,
    },

    isCheckingStock: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      },
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      },
    },

    // 長押多久開始連續攻擊，預設半秒
    longPressThrottle: {
      type: Number,
      required: false,
      default: () => {
        return 500
      },
    },

    isInShipmentItem: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      },
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    isQuantityInvalid() {
      return !this.isInShipmentItem && this.quantity > this.maxPurchaseableQuantity && this.isCheckingStock
    },

    maxPurchaseableQuantity() {
      return Math.floor(this.currentStock / this.pcsPerPackage)
    },

    isQuantityMeetCounterStop() {
      return this.quantity >= this.maxPurchaseableQuantity
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    onClick(event, callback) {
      if (event.detail !== 0 || event.type === 'click') return

      callback()
    },

    onStartLongPress(event, callback) {
      if (event.button !== 0 && event.type !== 'touchstart') return

      this._$intervalTime = new Date()
      this._clearInterval()
      this._$timerRef = setTimeout(() => {
        this._$intervalRef = setInterval(() => {
          callback()
        }, 88)
      }, this.longPressThrottle)
    },

    onStopLongPress(event, callback) {
      if (!this._$timerRef) return

      if (!this._$intervalRef) callback()
      this._clearInterval()
      this._clearTimer()
    },

    _clearTimer() {
      clearTimeout(this._$timerRef)
      this._$timerRef = null
    },

    _clearInterval() {
      clearInterval(this._$intervalRef)
      this._$intervalRef = null
    },

    increaseQuantity() {
      if (this.isConfirmed) return
      if (this.isQuantityMeetCounterStop && this.isCheckingStock) return

      this.$emit('update:quantity', this.quantity + 1)
    },

    decreaseQuantity() {
      if (this.isConfirmed) return
      if (this.quantity === 1) return
      if (this.maxPurchaseableQuantity !== 0 && this.quantity > this.maxPurchaseableQuantity)
        return this.$emit('update:quantity', this.maxPurchaseableQuantity)

      if (this.quantity > 1) this.$emit('update:quantity', this.quantity - 1)
    },
  },
}
</script>
