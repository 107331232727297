import ActionConfirmService from '@services/action_confirm_service'
import DateAndNumber from '@applicationComponents/return_authorization/date-and-number.vue'
import StateList from '@applicationComponents/return_authorization/state-list.vue'
import DetailUnit from '@applicationComponents/return_authorization/detail-unit.vue'
// import RefundInfo from '@applicationComponents/return_authorization/refund-info.vue'
import CommentList from '@applicationComponents/return_authorization/comment-list.vue'

export default {
  name: 'user-rma-show-container',

  components: {
    DateAndNumber,
    StateList,
    DetailUnit,
    CommentList,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      rmaNumber: undefined,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    returnAuthorization() {
      return this.$store.getters['returnAuthorizations/findByNumber'](this.rmaNumber)
    },
  },

  // created() {},

  mounted() {
    this.rmaNumber = this.$el.dataset.number
    this.$store.dispatch('users/getReturnAuthorization', this.rmaNumber)
  },

  methods: {
    confirmCancel() {
      new ActionConfirmService().confirm(this.cancel)
    },

    cancel() {
      this.$store.dispatch('returnAuthorizations/cancel', this.returnAuthorization)
    },
  },
}
