<template lang="pug">

.vc-order-calculated-price-detail
  ul.wrapper
    li.detail-unit
      .inner(:data-minus="false")
        .label {{ `${attributeLocaleText('order', 'item_total')}(${copyLocaleText('pre_tax')})` }}
        .price(data-currency="TWD"
              :data-empty="itemTotal === 0") {{ toMoney(itemTotal).format() }}
      ul
        li.inner.sub(v-if="itemTotal !== 0"
                     :data-minus="false")
          .label 5% 稅金
          .price(data-currency="TWD"
                 :data-empty="itemTotal === 0") {{ taxOfItemTotal.format() }}
        li.inner.sub(v-for="adjustment in adjustmentsToItem"
                    :key="adjustment.id"
                    :data-minus="adjustment.amount < 0")
          .label {{ adjustment.title }}
          .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}
    li.detail-unit
      .inner(:data-minus="false")
        .label {{ attributeLocaleText('order', 'shipment_total') }}
        .price(data-currency="TWD"
              :data-empty="orderOfGroup.shipment_total === 0") {{ shipmentTotal }}
      ul
        li.inner.sub(v-for="adjustment in adjustmentsToShipment"
                    :key="adjustment.id"
                    :data-minus="adjustment.amount < 0")
          .label {{ adjustment.title }}
          .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}
    li.detail-unit(v-if="isNotConfirmedYet")
      .inner(:data-minus="false")
        .label {{ modelNameLocaleText('promotion') }}
        .price {{ actionLocaleText('calculating') }}
    li.detail-unit(v-if="adjustmentsToOrder.length > 0")
      .inner(v-for="adjustment in adjustmentsToOrder"
             :key="adjustment.id"
             :data-minus="adjustment.amount < 0")
        .label {{ adjustment.title }}
        .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}

    .order-total
      .label
        span {{ attributeLocaleText('order', 'total') }}
        br
        span.tax-note(v-if="isNotDuringCartFlow") {{ attributeLocaleText('order', 'include_business_tax', { tax: toMoney(orderOfGroup.tax_total).format() }) }}
      .price(data-currency="TWD") {{ toMoney(orderTotal).format() }}

  //- .notice-message-unit
    .icon
      i.fa.fa-info-circle
    span 訂單達 $400 以上即可享免運費優惠。

</template>

<script>
import B2BTaxCalculator from '@services/b_2_b_tax_calculator.js'
import priceDetailMixin from '../mixins/price_detail_mixin.js'

const PREORDER_TYPE_SUFFIX = '_preorder'

export default {
  // components: {},
  mixins: [priceDetailMixin],

  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isNotDuringCartFlow() {
      return !['cart', 'items_confirmed'].includes(this.order.order_state)
    },

    isNotConfirmedYet() {
      return ['cart', 'items_confirmed'].includes(this.orderOfGroup.order_state)
    },

    itemIds() {
      return this.cartItems.map((item) => item.id)
    },

    shipmentIds() {
      return this.orderOfGroup.shipments.map((shipment) => shipment.id)
    },

    paymentId() {
      return this.orderOfGroup.payment.id
    },

    adjustmentsToItem() {
      return this.adjustments.filter(
        (adjustment) =>
          adjustment.adjustable_type === 'Order::Item' && this.itemIds.includes(String(adjustment.adjustable_id))
      )
    },

    adjustmentsToOrder() {
      if (this.isNotDuringCartFlow) {
        return this.adjustments.filter(
          (adjustment) => adjustment.adjustable_type === 'Order' && adjustment.adjustable_id === this.orderOfGroup.id
        )
      } else {
        return []
      }
    },

    adjustmentsToShipment() {
      return this.adjustments.filter(
        (adjustment) =>
          adjustment.adjustable_type === 'Order::Shipment' &&
          this.shipmentIds.includes(String(adjustment.adjustable_id))
      )
    },

    isPreorder() {
      return this.productType.includes(PREORDER_TYPE_SUFFIX) || this.order.is_preorder
    },

    orderOfGroup() {
      if (this.isNotDuringCartFlow) {
        const ordersOfProductType = this.$store.getters['orders/all'].filter(
          (order) => order.product_type === this.productType.replace(PREORDER_TYPE_SUFFIX, '')
        )

        if (this.isPreorder) return ordersOfProductType.find((order) => order.is_preorder)
        return ordersOfProductType.find((order) => !order.is_preorder)
      } else {
        return this.order
      }
    },

    itemTotal() {
      if (this.isNotConfirmedYet) {
        return this.cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0)
      } else {
        return this.orderOfGroup.item_total
      }
    },

    taxOfItemTotal() {
      return this.computeTax(this.toMoney(this.itemTotal)).taxAmount
    },

    shipmentTotal() {
      if (this.isNotConfirmedYet) return this.actionLocaleText('calculating')
      return this.toMoney(this.orderOfGroup.shipment_total).format()
    },

    orderTotal() {
      if (this.isNotConfirmedYet) {
        return (
          this.itemTotal +
          this.taxOfItemTotal.cents +
          this.adjustmentsToItem.reduce((sum, adjustment) => sum + adjustment.amount, 0)
        )
      } else {
        return this.orderOfGroup.total
      }
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    computeTax(amount, currency = 'TWD') {
      return B2BTaxCalculator.compute(amount, currency)
    },
  },
}
</script>
