import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_shipments',
  attributes: [
    'id',
    'tracking_code',
    'number',
    'cost',
    'shipped_at',
    'delivered_at',
    'order',
    'address',
    'shipping_method',
    'shipment_items',
    'variants',
    'items',
    'state',
    'order_number',
    'adjustment_total',
    'promotion_total',
    'gateway_info',
    'latest_notification',
    'order_completed_at',
    'total_fee',
    'logistic_created_at',
    'created_at',
    'updated_at',
    'tallied_at',
    'pre_tax_total',
    'tax_amount',
    'post_tax_total',
    'buyer_name',
    'buyer_phone',
    'customer_code',
    'order_total',
  ],
  editableAttributes: ['tracking_code'],
}

export default class OrderShipment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.isPreorder === true) queryString += `&is_preorder=1`
    if (options.isPreorder === false) queryString += `&is_preorder=0`
    if (options.productType) queryString += `&product_type=${options.productType}`

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  static tallyInBatches(shipmentIds = []) {
    if (shipmentIds.length <= 0) return

    const requestBody = {
      data: {
        type: 'tally_shipment_in_batches',
        attributes: {
          shipment_ids: shipmentIds,
        },
      },
    }

    return axios.post(`${new this().apiBasePath()}/tally_in_batches`, requestBody)
  }

  tally() {
    return axios.post(`${this.apiBasePath()}/${this.id}/tally`)
  }

  static shipInBatches(shipmentIds = []) {
    if (shipmentIds.length <= 0) return

    const requestBody = {
      data: {
        type: 'ship_shipment_in_batches',
        attributes: {
          shipment_ids: shipmentIds,
        },
      },
    }

    return axios.post(`${new this().apiBasePath()}/ship_in_batches`, requestBody)
  }

  ship() {
    const requestBody = {
      data: {
        type: 'order_shipment_ship',
        attributes: {
          tracking_code: this.tracking_code,
        },
      },
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/ship`, requestBody)
  }

  // extra methods or helpers here...

  stateType() {
    return {
      'is-default': this.state === 'pending',
      'is-warning': this.state === 'ready',
      'is-info': this.state === 'tallying',
      'is-success': ['shipped', 'delivered'].includes(this.state),
      'is-danger': this.state === 'canceled',
    }
  }

  canTally() {
    return ['ready'].includes(this.state)
  }

  canShip() {
    return ['ready', 'tallying'].includes(this.state)
  }

  hasBackorderedItems() {
    return this.shipment_items.filter((item) => item.state === 'backordered').length > 0
  }

  fullAddress() {
    return `${this.address.address.zip || ''} ${this.address.address.city || ''} ${this.address.address.area || ''} ${
      this.address.address.street_address
    }`.trim()
  }

  storeFullAddress() {
    return `${this.address.address.store_info.address.zip || ''} ${
      this.address.address.store_info.address.city || ''
    } ${this.address.address.store_info.address.area || ''} ${
      this.address.address.store_info.address.street_address
    }`.trim()
  }

  createLogistic(options = {}) {
    return axios.post(`${this.apiBasePath()}/${this.id}/create_logistic`, options)
  }

  isCvsLogistic() {
    return ['fami_b2c', 'fami_c2c', 'unimart_b2c', 'unimart_c2c', 'hilife_b2c', 'hilife_c2c'].includes(
      this.address.address.extra_data
    )
  }

  isStoreLogistic() {
    return this.address.address.extra_data === 'store'
  }

  fetchLogisticLabelFormData(options = {}) {
    return axios.post(`${this.apiBasePath()}/${this.id}/logistic_label`, options)
  }

  isLogisticShipment() {
    return ['ecpay'].includes(this.gateway_info.provider)
  }

  isAbleToCreateLogistic() {
    return !this.logistic_created_at && this.isLogisticShipment()
  }

  isAbleToPrintLogisticLabel() {
    return !!this.logistic_created_at && this.isLogisticShipment()
  }

  isAbleToShip() {
    if (!this.canShip()) return false

    if (this.isLogisticShipment()) {
      return !!this.logistic_created_at
    } else {
      return true
    }
  }

  batchOperationCheck(mode) {
    if (!mode) return false

    switch (mode) {
      case 'tally':
        return this.canTally()
      case 'ship':
        return this.canShip()
      case 'createLogistic':
        return this.isLogisticShipment() && this.isAbleToCreateLogistic() && this.isCvsLogistic()
      case 'printLogisticLabel':
        return this.isAbleToPrintLogisticLabel()
    }
  }

  trackingCode() {
    let data = this.latest_notification && this.latest_notification.information

    if (!data) return

    switch (this.gateway_info.provider) {
      case 'ecpay':
        return (this.isCvsLogistic() ? data.cvs_payment_no : data.booking_note) || ''
      default:
        return
    }
  }
}
