import squaresIcon from '../../../images/squares.svg'
import BrandList from '@applicationComponents/distribution_base/brand-list.vue'

export default {
  name: 'distribution-base-show-page-container',

  components: {
    BrandList,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      distributionBaseId: null,
    }
  },

  computed: {
    distributionBase() {
      return this.$store.getters['distributionBases/find'](this.distributionBaseId)
    },

    brands() {
      return this.$store.getters['brands/all']
    },

    isDistributionBaseLoaded() {
      return typeof this.distributionBase.id === 'string'
    },

    sectionHeaderIcon() {
      return squaresIcon
    },
  },

  // created() {},

  mounted() {
    this.distributionBaseId = this.$el.dataset.distributionBase

    this.$store.dispatch('distributionBases/find', this.distributionBaseId)
  },

  // methods: {}
}
