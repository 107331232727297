<template lang="pug">

.vc-return-authorization-apply-wizard
  number-and-placed-date(:order="order")

  template(v-if="isFormReady")
    template(v-if="step === 1")
      shipment-detail(v-for="(shipment, index) in shipments"
                      :shipment="shipment"
                      :form="form"
                      :index="index"
                      :key="shipment.id")
      .odd-container.options
        button.button.is-odd(:disabled="this.form.items.length < 1"
                             @click="nextStep"
                             :class="{ 'is-loading': isLoading }") {{ actionLocaleText('next_step') }}

    template(v-if="step === 2")
      rma-form(:form="form")
      .odd-container.options
        button.button.is-default(@click="prevStep") {{ actionLocaleText('prev_step') }}
        button.button.is-odd(@click="submit"
                             :class="{ 'is-loading': isLoading }") {{ actionLocaleText('submit') }}

</template>

<script>
import Form from 'odd-form_object'
import ReturnAuthorization from '@models/return_authorization.js'
import NumberAndPlacedDate from '../order/number-and-placed-date.vue'
import ShipmentDetail from '../order_shipment/detail.vue'
import RmaForm from './form.vue'

export default {
  components: {
    NumberAndPlacedDate,
    ShipmentDetail,
    RmaForm,
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true,
    },

    returnAuthorization: {
      type: Object,
      required: false,
      default: () => {
        return new ReturnAuthorization()
      },
    },
  },

  data() {
    return {
      step: 1,
      form: new Form(this.returnAuthorization),
      isFormReady: false,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    shipments() {
      return this.$store.getters['orderShipments/all']
    },
  },
  // created() {},
  mounted() {
    if (this.form.items === undefined) this.form.items = []
    this.isFormReady = true
  },

  methods: {
    nextStep() {
      this.step += 1
    },

    prevStep() {
      this.step -= 1
    },

    submit() {
      this.$store
        .dispatch('users/applyRma', {
          orderToken: this.order.id,
          formData: this._buildFormData(),
        })
        .then((response) => {
          Turbolinks.visit(`/user/return_authorizations/${response.data.data.attributes.number}?created=1`)
        })
    },

    _buildFormData() {
      let formData = new FormData()
      const returnAuthorization = this.form.sync()

      returnAuthorization.items.forEach((returnItem, index) => {
        formData.append(`return_authorization[items][][shipment_item_id]`, returnItem.shipment_item_id)
        formData.append(`return_authorization[items][][return_quantity]`, returnItem.return_quantity)
        formData.append(`return_authorization[items][][description]`, returnItem.description)
        formData.append(`return_authorization[items][][reimbursement_type]`, returnItem.reimbursement_type)
        returnItem.images.forEach((image, index) => {
          formData.append(`return_authorization[items][][images][][file]`, image)
        })
      })

      return formData
    },
  },
}
</script>
