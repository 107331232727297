import OrderItem from '@models/order_item'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new OrderItem(state.entities[id]))
}

export const fromCurrentOrder = (state) => {
  if (!state.currentOrderId) return []

  return all(state).filter((item) => state.splitResults.includes(item.order.id))
}

export const currentOrderId = (state) => {
  return state.currentOrderId
}

export const splitResults = (state) => {
  return state.splitResults
}

export const rawEntities = (state) => {
  return {
    entities: state.entities,
    result: state.result,
  }
}

export const find = (state) => (id) => {
  return new OrderItem(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
