<template lang="pug">

.vc-return-authorization-comment-list
  b-collapse(animation="slide"
            :open.sync="isCommentsShow")
    .title-bar(slot="trigger"
               :class="{ '-is-open': isCommentsShow }")
      .left
        span.text {{ attributeLocaleText('return_authorization', 'comments') }}
      .right
        span.number {{ comments.length }}
        .icon
          i.fa.fa-caret-right

    .comments-wrapper
      comment-unit(v-for="comment in comments"
                  :key="comment.id"
                  :comment="comment")

      button.button.is-primary.is-block(@click="openCommentForm")
        .icon
          i.fa.fa-pencil-square-o
        span {{ actionLocaleText('add_new', { model: modelNameLocaleText('comment') }) }}

</template>

<script>
import CommentUnit from '@applicationComponents/comment/unit.vue'
import CommentForm from './comment-form.vue'

export default {
  components: {
    CommentUnit,
    CommentForm,
  },
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isCommentsShow: true,
    }
  },

  computed: {
    comments() {
      return this.$store.getters['comments/all']
    },
  },

  created() {
    this.$store.dispatch('returnAuthorizations/fetchComments', {
      model: this.returnAuthorization,
      options: {
        sort: 'updated_at',
      },
    })
  },
  // mounted() {},
  methods: {
    openCommentForm() {
      this.$buefy.modal.open({
        parent: this,
        component: CommentForm,
        props: {
          returnAuthorization: this.returnAuthorization,
        },
      })
    },
  },
}
</script>
