<template lang="pug">

.vc-common-member-cart-button.function-button
  a.link(@click="goToCartPage")
    .icon
      i.fa.fa-shopping-cart
    span.text {{ copyLocaleText('cart') }}
    span.counter.tag.is-odd.is-small.is-rounded(v-if="cartItemCount") {{ cartItemCount }}

</template>

<script>
import ShoppingCartService from '@services/shopping_cart_service.js'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      cartService: undefined,
    }
  },

  computed: {
    cartItemCount() {
      return this.$store.getters['orderItems/fromCurrentOrder'].reduce((prevValue, currentItem) => {
        if (currentItem.use_type === 'gift') return prevValue
        return prevValue + currentItem.quantity
      }, 0)
    },

    _shouldFetchCurrentOrder() {
      const doNotFetchCurrentOrderPathes = ['/cart', '/guest/orders/', '/user/orders/']

      return !doNotFetchCurrentOrderPathes.some((path) => window.location.pathname.includes(path))
    },
  },

  watch: {
    cartItemCount: {
      handler(newValue) {
        if (window.location.pathname !== '/cart') return

        if (newValue === 0) {
          this.$localforage.getItem('redirect-path').then((path) => {
            Turbolinks.visit(path || '/?cart_empty=1')
          })
        }
      },
    },
  },

  // created() {},
  mounted() {
    this.cartService = new ShoppingCartService(this.$localforage, this.$store)

    // NOTE: this is a workaround for fix problem that order item not show in guest order page.
    //       maybe we should store current order item in different namespace that wont conflict with
    //       normal order item (?)
    if (this._shouldFetchCurrentOrder) this.cartService.fetchCurrentOrder()
  },

  methods: {
    goToCartPage() {
      const currentPath = window.location.pathname

      if (currentPath === '/cart') return
      if (this.cartItemCount > 0) return this._setRedirectPathAndVisitCartPage(currentPath)

      this.cartService
        .fetchCurrentOrder({
          force: true,
        })
        .then((_) => {
          if (this.cartItemCount > 0) {
            this._setRedirectPathAndVisitCartPage(currentPath)
          } else {
            this.$store.dispatch('addFlashMessage', ['notice', this.messageLocaleText('cart_is_empty_now')])
          }
        })
    },

    _setRedirectPathAndVisitCartPage(currentPath) {
      this.$localforage.setItem('redirect-path', currentPath + window.location.search).then(() => {
        Turbolinks.visit('/cart')
      })
    },
  },
}
</script>
