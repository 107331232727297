import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import ExtraQueryStringParseService from '@services/extra_query_string_parse_service.js'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'category_system_categories',
  attributes: [
    'id',
    'name',
    'name_with_parents',
    'description',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'cover',
    'category_system_id',
    'parent_id',
    'is_default',
    'default_usage',
    'lft',
    'rgt',
    'depth',
    'created_at',
    'updated_at',
    'position',
  ],
  editableAttributes: [
    'name',
    'description',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'category_system_id',
    'parent_id',
  ],
}

export default class Category extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static byType(options = {}) {
    const additionalParams = ['type', 'level']
    let queryString = FetchingDataOptionsService.call(options)

    additionalParams.forEach((param) => {
      if (options[param]) queryString += `&${param}=${options[param]}`
    })

    return axios.get(`${new this().apiBasePath()}/by_type?${queryString}`)
  }

  static byUsage(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}/by_usage?${FetchingDataOptionsService.call(options)}&usage=${options.usage}`
    )
  }

  fetchContents(options = {}) {
    const subCategoriesIncludedParams = options.sub_categories_included ? '&sub_categories_included=1' : ''

    return axios.get(
      `${this.apiBasePath()}/${this.id}/contents?${FetchingDataOptionsService.call(
        options
      )}${subCategoriesIncludedParams}`
    )
  }

  fetchSalesEvents(options = {}) {
    const subCategoriesIncludedParams = options.sub_categories_included ? '&sub_categories_included=1' : ''

    return axios.get(
      `${this.apiBasePath()}/${this.id}/sales_events?${FetchingDataOptionsService.call(
        options
      )}${subCategoriesIncludedParams}`
    )
  }

  fetchProducts(options = {}) {
    const subCategoriesIncludedParams = options.sub_categories_included ? '&sub_categories_included=1' : ''

    return axios.get(
      `${this.apiBasePath()}/${this.id}/products?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}${subCategoriesIncludedParams}`
    )
  }

  fetchIndexCustomCategoryProducts(options = {}) {
    const KEY = options.key

    return axios.get(
      `${this.apiBasePath()}/${this.id}/products/site_index_custom_records?${FetchingDataOptionsService.call(
        options
      )}&key=${KEY}`
    )
  }

  // extra methods or helpers here...
  is_root() {
    return this.parent_id === null
  }

  is_leaf() {
    return this.rgt - this.lft === 1
  }
}
