export default {
  // components: {},

  props: {
    order: {
      type: Object,
      required: true,
    },

    cartItems: {
      type: Array,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    adjustments() {
      // 不顯示贈品的 adjustment
      return this.$store.getters['orders/allAdjustments'].filter(
        (adjustment) => !this.order.gift_items.map((gift) => gift.id).includes(String(adjustment.adjustable_id))
      )
    },

    adjustmentsToItem() {
      return this.adjustments.filter((adjustment) => adjustment.adjustable_type === 'Order::Item')
    },

    adjustmentsToOrder() {
      return this.adjustments.filter((adjustment) => adjustment.adjustable_type === 'Order')
    },

    adjustmentsToShipment() {
      return this.adjustments.filter((adjustment) => adjustment.adjustable_type === 'Order::Shipment')
    },

    shipmentTotal() {
      if (['cart', 'items_confirmed'].includes(this.order.order_state)) return this.actionLocaleText('calculating')
      return this.toMoney(this.order.shipment_total).format()
    },
  },
  // methods: {}
}
