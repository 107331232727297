export const FETCH_PRODUCT_VARIANTS_SUCCESS = 'FETCH_PRODUCT_VARIANTS_SUCCESS'
export const GET_RELATED_PRODUCT_VARIANTS_SUCCESS = 'GET_RELATED_PRODUCT_VARIANTS_SUCCESS'
export const GET_PRODUCT_VARIANT_SUCCESS = 'GET_PRODUCT_VARIANT_SUCCESS'
export const ADD_PRODUCT_VARIANT_SUCCESS = 'ADD_PRODUCT_VARIANT_SUCCESS'
export const UPDATE_PRODUCT_VARIANT_SUCCESS = 'UPDATE_PRODUCT_VARIANT_SUCCESS'
export const DELETE_PRODUCT_VARIANT_SUCCESS = 'DELETE_PRODUCT_VARIANT_SUCCESS'
export const FETCH_PRODUCT_VARIANT_ACTIVITIES_SUCCESS = 'FETCH_PRODUCT_VARIANT_ACTIVITIES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
