<template lang="pug">

transition(enter-active-class="animated slideInLeft"
           leave-active-class="animated slideOutLeft")
  .vc-common-mobile-menu(v-if="mobileMenuStates['isMobileMenuActive']")
    //- Root Menu
    transition(enter-active-class="animated slideInLeft"
               leave-active-class="animated slideOutLeft")
      .menu-wrapper.root(v-if="!mobileMenuStates['isCategoriesSelectorActive']")
        ul.mobile-menu-items
          li.mobile-menu-item
            a.link(href="/"
                  @click="linkClickedHandler")
                span.text {{ copyLocaleText('nav_menu.home') }}
          li.mobile-menu-item
            a.link(href="#"
                  @click.prevent="categoriesSelectorTriggerClickedHandler('product')")
              span.text {{ copyLocaleText('nav_menu.shop') }}
              .icon
                i.fa.fa-chevron-right
          li.mobile-menu-item
            a.link(href="/sales_events"
                   @click.prevent="categoriesSelectorTriggerClickedHandler('sales_event')")
              span.text {{ copyLocaleText('flash_sale') }}
              .icon
                i.fa.fa-chevron-right
          li.mobile-menu-item
            a.link(href="/news_posts"
                  @click="linkClickedHandler")
              span.text {{ copyLocaleText('nav_menu.news') }}
          li.mobile-menu-item
            a.link(href="/abouts"
                  @click="linkClickedHandler")
              span.text {{ copyLocaleText('nav_menu.about') }}
          li.mobile-menu-item
            a.link(href="/#contact"
                   data-turbolinks="false"
                   @click="linkClickedHandler")
              span.text {{ copyLocaleText('nav_menu.contact') }}

    //- Category list menu
    transition(enter-active-class="animated slideInRight"
               leave-active-class="animated slideOutRight")
      categoryList(v-if="currentActivatedCategory == 'product'")
      categoryList(v-if="currentActivatedCategory == 'sales_event'"
                   :show-usages="false")

</template>

<script>
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import debounce from 'lodash.debounce'
import categoryList from './mobile-category-list'

export default {
  components: {
    categoryList,
  },
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },

  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    currentActivatedCategory() {
      if (this.mobileMenuStates['isCategoriesSelectorActive']) {
        return this.mobileMenuStates['activatedCategoryType']
      }
    },
  },

  watch: {
    'mobileMenuStates.isMobileMenuActive': {
      immediate: true,
      handler: function (isActived) {
        this.$nextTick(() => {
          let target = document.querySelector('.vc-common-mobile-menu')

          if (!target) return

          if (isActived) {
            document.documentElement.classList.add('no-scroll')
            return disableBodyScroll(target)
          }

          document.documentElement.classList.remove('no-scroll')
          enableBodyScroll(target)
        })
      },
    },
  },
  // created() {},
  mounted() {
    this.detectInnerHeight()
    window.addEventListener('scroll', this.detectInnerHeight, 200)
    window.addEventListener('resize', this.detectInnerHeight, 200)
  },

  beforeDestroy() {
    clearAllBodyScrollLocks()
    document.documentElement.classList.remove('no-scroll')
    window.removeEventListener('scroll', this.detectInnerHeight, 200)
    window.removeEventListener('resize', this.detectInnerHeight, 200)
  },

  methods: {
    detectInnerHeight: debounce(function () {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 200),

    linkClickedHandler(event) {
      this.$store.dispatch('toggleMobileMenu', Object.assign(this.mobileMenuStates, { isMobileMenuActive: false }))
    },

    categoriesSelectorTriggerClickedHandler(categoryType) {
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, {
          isCategoriesSelectorActive: true,
          activatedCategoryType: categoryType,
        })
      )
    },
  },
}
</script>
