<template lang="pug">

.vc-order-final-confirmation.odd-container.has-padding
  b-loading(:active="isLoading")

  grouped-items-unit(v-for="productType in productTypeList"
                     :key="productType"
                     :product-type="productType"
                     :order="order"
                     :cart-items="groupedItems[productType]"
                     :current-step="currentStep"
                     :cart-service="cartService")

  addresses-and-extra-info(:order="order"
                           :cart-items="cartItems"
                           :cart-service="cartService")

  .cart-options
    back-to-store-button
    payment-button(:order="order"
                   :cart-service="cartService"
                   @back-to-first-step="backToFirstStepHandler")


</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import GroupedItemsUnit from './grouped-items-unit.vue'
import AddressesAndExtraInfo from './addresses-and-extra-info.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'
import BackToStoreButton from './back-to-store-button.vue'
import PaymentButton from './payment-button.vue'

export default {
  components: {
    GroupedItemsUnit,
    AddressesAndExtraInfo,
    ShippingMethodSelector,
    BackToStoreButton,
    PaymentButton,
  },

  mixins: [checkoutFlowMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  mounted() {
    this._tryFetchPaymentMethods()
    this._trackCheckoutProgress()
  },

  methods: {
    backToFirstStepHandler() {
      this._scrollToTop()
      this.$emit('update:currentStep', 1)
    },
  },
}
</script>
