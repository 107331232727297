import SlideshowContainer from '@applicationComponents/common/slideshow-container.vue'
import BrandList from '@applicationComponents/brand/list.vue'
import squaresIcon from '../../../images/squares.svg'

export default {
  name: 'brands-page-container',

  components: {
    SlideshowContainer,
    BrandList,
  },

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    icon() {
      return squaresIcon
    },
  },

  // created() {},

  // mounted() {},

  // methods: {}
}
