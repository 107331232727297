<template lang="pug">

.vc-order-grouped-items-unit
  label.label {{ `${systemLocaleText(`product_categories.${productType}`)} ${modelNameLocaleText('order')}` }}
  cart-item-list(:order="order"
                 :cart-items="cartItems"
                 :current-step="currentStep"
                 :cart-service="cartService")
  .form-container
    .form-column.-clean

    .form-column.-clean
      .section-label {{ attributeLocaleText('order', 'price_detail') }}
      .form-section
        div(:is="priceDetailComponent"
            :order="order"
            :cart-items="normalCartItems"
            :product-type="productType")
      .form-section(v-if="isWeeklyPaymentType")
        .weekly-payment-indicator
          .icon
            i.fa.fa-info-circle
          span {{ copyLocaleText('this_order_is_weekly_payment_type') }}

</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'

export default {
  components: {
    CartItemList,
  },

  mixins: [checkoutFlowMixin],

  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isWeeklyPaymentType() {
      return ['healthy'].includes(this.productType)
    },

    normalCartItems() {
      return this.cartItems.filter((item) => item.use_type !== 'gift')
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
