import SlideshowContainer from '@applicationComponents/common/slideshow-container.vue'
import HotProducts from '@applicationComponents/product/hot-products.vue'
import SelectProducts from '@applicationComponents/category/select-products.vue'
import Product from '@models/product.js'
import MobileQuickLinkWrapper from '@applicationComponents/common/mobile-quick-link-wrapper.vue'
import InfoLinkUseTypeContainer from '@applicationComponents/info_link/use-type-container.vue'

export default {
  name: 'landing-page-container',

  components: {
    SlideshowContainer,
    HotProducts,
    SelectProducts,
    MobileQuickLinkWrapper,
    InfoLinkUseTypeContainer,
  },

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  // computed: {},

  // created() {},

  // mounted() {},

  // methods: {}
}
