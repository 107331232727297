<template lang="pug">

.vc-category-select-products
  .tabs-wrapper
    b-tabs(size="is-small"
           expanded
           @change="categoryChangeHandler")
      b-tab-item(v-for="category in categories"
                  :label="category.name"
                  :key="category.id")

  product-card(v-for="(product, index) in selectGoods"
               :key="product.id"
               :product="product"
               :index="index"
               :list-location="listLocation"
               @ga-impressed="impressedHandler")

  b-loading(:active.sync="isLoading"
            :is-full-page="false")
</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import ProductCard from '../product/card.vue'

export default {
  components: {
    ProductCard,
  },

  mixins: [productImpressionMixin],

  props: {
    productsCount: {
      type: Number,
      required: false,
      default: () => {
        return 12
      },
    },
  },

  data() {
    return {
      productIds: [],
      currentCategoryIndex: 0,
    }
  },

  computed: {
    categories() {
      return this.$store.getters['categorySystems/allCategories']({ depth: 1 }).filter(
        (category) => category.default_usage !== 'retail'
      ) // 不顯示零售商品
    },

    currentCategory() {
      return this.categories[this.currentCategoryIndex]
    },

    selectGoods() {
      return this.$store.getters['products/allByGivenIds'](this.productIds)
    },

    listLocation() {
      return `Select Goods - ${this.currentCategory.name}`
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },
  },

  created() {
    if (this.categories.length === 0) {
      this.$store
        .dispatch('categorySystems/fetchCategoriesByType', { type: 'product', sort: 'created_at' })
        .then((_) => {
          this.fetchCategorySelectGoodsFor(this.currentCategory)
        })
    } else {
      this.this.fetchCategorySelectGoodsFor(this.currentCategory)
    }
  },

  // mounted() {},
  methods: {
    categoryChangeHandler(event) {
      this.currentCategoryIndex = event
      this.fetchCategorySelectGoodsFor(this.categories[event])
    },

    fetchCategorySelectGoodsFor(category) {
      return this.$store
        .dispatch('categorySystems/fetchIndexCustomCategoryProducts', {
          model: category,
          options: {
            pageNumber: 1,
            pageSize: this.productsCount,
            noReplace: true,
            key: `${this.currentCategory.default_usage}_product_ids`,
          },
        })
        .then((response) => {
          this.productIds = response.data.data.map((product) => product.id)
        })
    },
  },
}
</script>
