import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_variants',
  attributes: [
    'consumer_price',
    'cost_price',
    'cover',
    'depth',
    'description',
    'discount_rate',
    'discounted_price',
    'has_more_variants',
    'height',
    'id',
    'images',
    'is_available',
    'is_discarded',
    'is_master',
    'member_price',
    'name',
    'option_value_ids',
    'original_price',
    'product_id',
    'product_name',
    'sell_price',
    'shipping_methods',
    'sku',
    'stock_status',
    'stock',
    'weight',
    'width',
    'pcs_per_package',
  ],
  editableAttributes: [
    'product_id',
    'name',
    'sku',
    'price',
    'weight',
    'width',
    'depth',
    'height',
    'description',
    'stock_params',
    'option_value_ids',
  ],
}

export default class ProductVariant extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  setAsMaster() {
    return axios.put(`${this.apiBasePath()}/${this.id}/set_as_master`)
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  updateStock() {
    const requestBody = {
      data: {
        type: 'update-product-variant-stock',
        attributes: {
          quantity: parseInt(this.stock_params.quantity),
          action: this.stock_params.action,
          reason: this.stock_params.reason,
        },
      },
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/stock`, requestBody)
  }

  fetchActivities(options) {
    return axios.get(`${this.apiBasePath()}/${this.id}/activities?${FetchingDataOptionsService.call(options)}`)
  }

  // extra methods or helpers here...
  get stockStatusType() {
    return {
      'is-success': true,
      'is-warning': this.stock_status === 'low_stock',
      'is-danger': ['no_stock', 'not_enough'].includes(this.stock_status),
    }
  }

  hasDiscount() {
    return this.discount_rate < 1
  }

  requestBody() {
    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: this.attributes(),
      },
    }
  }

  displayPicture(size = 'thumb') {
    return this.cover[size].url
  }

  displayDiscountRate() {
    let rateNumber = this.discount_rate.toFixed(2).toString().split('.')[1]

    if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)

    return `${rateNumber} ${I18n.t('activerecord.attributes.product.discount_unit')}`
  }

  displaySize() {
    const properties = ['width', 'depth', 'height']
    let result = []

    properties.forEach((property) => {
      if (this[property]) result.push(`${I18n.t(`activerecord.attributes.product.${property}`)} ${this[property]}`)
    })

    if (result.length > 0) {
      return result.join(' × ')
    } else {
      return I18n.t('messages.data_not_provided')
    }
  }

  displayName() {
    if (this.is_master) {
      return this.name || I18n.t('activerecord.attributes.product/variant.is_master')
    } else {
      return this.name
    }
  }

  nameWithSku() {
    if (this.name) {
      return `${this.name} - ${this.sku}`
    } else {
      return this.sku || I18n.t('activerecord.attributes.product/variant.is_master')
    }
  }
}
