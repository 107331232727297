<template lang="pug">

.vc-category-sidebar-category-menu
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .decoration-stripe.-two.no-margin
  a.menu-trigger(href="#"
                 @click.prevent="menuTrigger")
    .icon
      img(:src="squaresIcon")
    .en(v-if="!selectedCategory || !isMobile") {{ copyLocaleText('all_x', { x: modelNameLocaleText('category_system/category') }) }}
    .text.zh(v-if="currentCategory && isMobile")
      | {{ currentCategory.name }}
    .text.sub-category-name(v-if="currentSubCategory && isMobile")
      | {{ currentSubCategory.name }}
  .decoration-stripe.-two.no-margin

  .usage-wrapper
    .usage-unit(v-for="usage in usages"
                v-if="usages.length > 0"
                :key="usage.id")
      .label(v-if="showUsages") {{ usage.name }}
      .category-unit(v-for="category in childrenOf(usage)"
                    :key="category.id")
        .inner-wrapper(:class="{ 'is-active': currentCategory && currentCategory.id === category.id }"
                      @click="selectCategory(category)")
          span.name {{ category.name }}
          .icon
            i.fa.fa-chevron-right

        transition(enter-active-class="animated fadeIn")
          .sub-categories(v-if="currentCategory && currentCategory.id === category.id")
            .sub-category-unit(v-for="subCategory in childrenOf(currentCategory)"
                                :key="subCategory.id"
                                :class="{ 'is-active': currentSubCategory && currentSubCategory.id === subCategory.id }"
                                href="#"
                                @click.prevent="selectSubCategory(subCategory)")
              span.name {{ subCategory.name }}

</template>

<script>
import queryString from 'query-string'
import squaresIcon from '../../../images/squares.svg'

const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  // components: {},
  // mixins: [],
  props: {
    categoryType: {
      type: String,
      required: true,
    },

    showUsages: {
      type: Boolean,
      default: () => {
        return true
      },
    },
  },

  data() {
    return {
      rootCategory: undefined,
      currentCategory: undefined,
      currentSubCategory: undefined,
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (this.$store.getters['windowWidth'] || window.innerWidth) < MOBILE_IMAGE_BREAK_POINT
    },

    squaresIcon() {
      return squaresIcon
    },

    selectedCategory() {
      return this.currentSubCategory || this.currentCategory
    },

    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    allCategories() {
      let rootCategory = this.rootCategory

      if (!rootCategory) return []

      return this.$store.getters['categorySystems/allCategories']().filter(
        (category) => category.lft >= rootCategory.lft && category.rgt <= rootCategory.rgt
      )
    },

    // 可用的「用途」選項
    usages() {
      return this.allCategories.filter((category) => category.depth === 1 && category.default_usage !== 'retail')
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },
  },
  // created() {},
  mounted() {
    if (this.usages.length > 0) return this.updateCategoryFromUrl()

    this.$store
      .dispatch(`categorySystems/fetchCategoriesByType`, {
        type: this.categoryType,
        sort: 'created_at',
        noReplace: true,
      })
      .then((response) => {
        let rootCategoryId = response.data.data.find((categoryRawData) => categoryRawData.attributes.depth === 0).id
        this.rootCategory = this.$store.getters['categorySystems/findCategory'](rootCategoryId)
      })
      .then(() => this.updateCategoryFromUrl())
  },

  methods: {
    childrenOf(category) {
      return this.$store.getters['categorySystems/childrenOf'](category).sort((a, b) => a.position - b.position)
    },

    updateCategoryFromUrl() {
      let updateFinalResult = () => {
        this.updateSelectedCategory()
        this.$emit('update:category-loaded', true)
      }

      let categoryId = queryString.parse(window.location.search)['category_id']

      if (!categoryId) return updateFinalResult()

      this.currentCategory =
        this.allCategories.find((category) => category.depth === 2 && category.id === categoryId) ||
        this.usages.find((category) => category.id === categoryId)

      if (!this.currentCategory) {
        this.currentSubCategory = this.allCategories.find(
          (category) => category.depth === 3 && category.id === categoryId
        )

        if (this.currentSubCategory) {
          this.currentCategory = this.allCategories.find(
            (category) => category.id === String(this.currentSubCategory.parent_id)
          )
        }
      }

      updateFinalResult()
    },

    selectCategory(category) {
      if (this.currentCategory && this.currentCategory.id === category.id) {
        this._unSelectTopCategory()
      } else {
        this.currentCategory = category
      }

      this.currentSubCategory = undefined
      this.updateSelectedCategory()
    },

    selectSubCategory(category) {
      this.currentSubCategory = category
      this.updateSelectedCategory()
    },

    updateSelectedCategory() {
      this.$emit('update:selected-category', this.selectedCategory)
    },

    resetSelectedCategory() {
      this.currentCategory = this.currentSubCategory = undefined
      this.updateSelectedCategory()
    },

    menuTrigger() {
      if (!this.isMobile) return this.resetSelectedCategory()

      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, {
          isMobileMenuActive: true,
          isCategoriesSelectorActive: true,
          activatedCategoryType: this.categoryType,
          isSkippingRootMenuToClose: true,
          selectedTopLevelCategoryId: this.currentCategory ? this.currentCategory.id : null,
        })
      )
    },

    /**
     * 若已經沒有被選擇的子類別，則取消選擇主類別
     */
    _unSelectTopCategory() {
      if (!this.currentSubCategory) {
        this.currentCategory = undefined
      }
    },
  },
}
</script>
