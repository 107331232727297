<template lang="pug">

.vc-product-info-card-mobile.is-mobile-only(v-if="product.isDataLoaded()")
  .product-info
    p.brand(v-if="product.brand_id") {{ product.brand_name }}
    p.name {{ product.name }}

  .price-info
    span {{ attributeLocaleText('product', 'price') }}
    .price
      span(v-if="product.hasDiscount()"
           data-currency="TWD")
        | {{ toMoney(product.original_price).format() }}
      span(data-currency="TWD")
        | {{ toMoney(product.consumer_price).format() }}
  .sticky-action-bar
    collect-button(:product="product")
    purchase-button(:product="product")

</template>

<script>
import CollectButton from './collect-button.vue'
import PurchaseButton from './purchase-button.vue'
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    CollectButton,
    PurchaseButton,
    Countdown,
  },
  // mixins: [],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
