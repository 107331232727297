export const FETCH_ORDER_ITEMS_SUCCESS = 'FETCH_ORDER_ITEMS_SUCCESS'
export const GET_RELATED_ORDER_ITEMS_SUCCESS = 'GET_RELATED_ORDER_ITEMS_SUCCESS'
export const GET_ORDER_ITEM_SUCCESS = 'GET_ORDER_ITEM_SUCCESS'
export const ADD_ORDER_ITEM_SUCCESS = 'ADD_ORDER_ITEM_SUCCESS'
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS'
export const DELETE_ORDER_ITEM_SUCCESS = 'DELETE_ORDER_ITEM_SUCCESS'
export const STORE_STATES_FROM_LOCAL = 'STORE_STATES_FROM_LOCAL'
export const SET_CURRENT_ORDER_ID = 'SET_CURRENT_ORDER_ID'
export const SET_SPLIT_RESULTS = 'SET_SPLIT_RESULTS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
