import * as types from './mutation-types'
import User from '@models/user'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    User.all(options)
      .then((response) => {
        commit(types.FETCH_USERS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    User.find(id)
      .then((response) => {
        commit(types.GET_USER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit,
            },
            params: id,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_USER_SUCCESS, response)
        } else {
          commit(types.UPDATE_USER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateProfile = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateProfile')

  return new Promise((resolve, reject) => {
    model
      .updateProfile()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_USER_SUCCESS, response)
        } else {
          commit(types.UPDATE_USER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfile,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_USER_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const check = ({ dispatch, commit }, email) => {
  return new Promise((resolve, reject) => {
    User.check(email)
      .then((response) => {
        resolve(response)
      })
      .catch((errors) => {
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: check,
            ref: {
              dispatch,
              commit,
            },
            params: email,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const getCollections = ({ dispatch, commit }, latestUpdatedAt = 0) => {
  commit(types.API_REQUEST_START, 'getCollections')

  return new Promise((resolve, reject) => {
    User.getCollections(latestUpdatedAt)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getCollections')
        if (response.status === 200) {
          dispatch('userCollections/receiveResourcesFromRelationships', response, {
            root: true,
          })
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getCollections,
            ref: {
              dispatch,
              commit,
            },
            params: latestUpdatedAt,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchCommonAddresses = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchCommonAddresses')

  return new Promise((resolve, reject) => {
    User.fetchCommonAddresses()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchCommonAddresses')
        dispatch('userCommonAddresses/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCommonAddresses,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchRecentOrders = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchRecentOrders')

  return new Promise((resolve, reject) => {
    User.fetchRecentOrders(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchRecentOrders')
        dispatch('orders/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchRecentOrders,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchOrders = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchOrders')

  return new Promise((resolve, reject) => {
    User.fetchOrders(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchOrders')
        dispatch('orders/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrders,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const getOrder = ({ dispatch, commit }, token) => {
  commit(types.API_REQUEST_START, 'getOrder')

  return new Promise((resolve, reject) => {
    User.getOrder(token)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getOrder')
        dispatch('orders/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('orders/receiveInvoicesFormRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('shippingMethods/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getOrder,
            ref: {
              dispatch,
              commit,
            },
            params: token,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const applyRma = ({ dispatch, commit }, { orderToken, formData }) => {
  commit(types.API_REQUEST_START, 'applyRma')

  return new Promise((resolve, reject) => {
    User.applyRma(orderToken, formData)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'applyRma')
        dispatch('returnAuthorizations/getResourceFromRelationship', response, { root: true })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: applyRma,
            ref: {
              dispatch,
              commit,
            },
            params: {
              orderToken,
              formData,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const getGuestOrder = ({ dispatch, commit }, { token, jwt }) => {
  commit(types.API_REQUEST_START, 'getOrder')

  return new Promise((resolve, reject) => {
    User.getGuestOrder(token, jwt)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getOrder')
        dispatch('orders/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('orders/receiveInvoicesFormRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('shippingMethods/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getOrder,
            ref: {
              dispatch,
              commit,
            },
            params: {
              token,
              jwt,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const currentOrder = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'currentOrder')

  return new Promise((resolve, reject) => {
    User.currentOrder(options)
      .then((response) => {
        let ids = response.data.data.relationships.split_results.data.map((splitResult) => splitResult.id)

        ids.push(response.data.data.id)

        commit(types.API_REQUEST_SUCCESS, 'currentOrder')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('orderItems/setCurrentOrderId', response.data.data.id, {
          root: true,
        })
        dispatch('orderItems/setSplitResults', ids, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: currentOrder,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const addItemToCart = ({ dispatch, commit }, requestBody) => {
  commit(types.API_REQUEST_START, 'addItemToCart')

  return new Promise((resolve, reject) => {
    User.addItemToCart(requestBody)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'addItemToCart')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: addItemToCart,
            ref: {
              dispatch,
              commit,
            },
            params: requestBody,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateCartItem = ({ dispatch, commit }, cartItem) => {
  commit(types.API_REQUEST_START, 'updateCartItem')

  return new Promise((resolve, reject) => {
    User.updateCartItem(cartItem)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'updateCartItem')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        // 更新 item 數量時可能會滿足優惠條件而加入贈品，
        // 把 variant 存入 vuex，才有資料可以顯示跟 gift item 有關的 新 variant
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateCartItem,
            ref: {
              dispatch,
              commit,
            },
            params: cartItem,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const removeCartItem = ({ dispatch, commit }, cartItem) => {
  commit(types.API_REQUEST_START, 'removeCartItem')

  return new Promise((resolve, reject) => {
    User.removeCartItem(cartItem)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'removeCartItem')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeCartItem,
            ref: {
              dispatch,
              commit,
            },
            params: cartItem,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const confirmItems = ({ dispatch, commit }, shippingMethodId) => {
  commit(types.API_REQUEST_START, 'confirmItems')

  return new Promise((resolve, reject) => {
    User.confirmItems(shippingMethodId)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'confirmItems')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: confirmItems,
            ref: {
              dispatch,
              commit,
            },
            params: shippingMethodId,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const placeOrder = ({ dispatch, commit }, order) => {
  commit(types.API_REQUEST_START, 'placeOrder')

  return new Promise((resolve, reject) => {
    User.placeOrder(order)
      .then((response) => {
        let ids = response.data.data.relationships.split_results.data.map((splitResult) => splitResult.id)

        ids.push(response.data.data.id)

        commit(types.API_REQUEST_SUCCESS, 'placeOrder')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('orderItems/setSplitResults', ids, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: placeOrder,
            ref: {
              dispatch,
              commit,
            },
            params: order,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const applyCoupon = ({ dispatch, commit }, code) => {
  commit(types.API_REQUEST_START, 'applyCoupon')

  return new Promise((resolve, reject) => {
    User.applyCoupon(code)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'placeOrder')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: applyCoupon,
            ref: {
              dispatch,
              commit,
            },
            params: code,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const removeCoupon = ({ dispatch, commit }, code) => {
  commit(types.API_REQUEST_START, 'removeCoupon')

  return new Promise((resolve, reject) => {
    User.removeCoupon(code)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'placeOrder')
        dispatch('orders/getCurrentOrderFromRelationship', response, {
          root: true,
        })
        dispatch('orderItems/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeCoupon,
            ref: {
              dispatch,
              commit,
            },
            params: code,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchReturnAuthorizations = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchReturnAuthorizations')

  return new Promise((resolve, reject) => {
    User.fetchReturnAuthorizations(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchReturnAuthorizations')
        dispatch('returnAuthorizations/receiveResourcesFromRelationships', response, { root: true })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchReturnAuthorizations,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const getReturnAuthorization = ({ dispatch, commit }, number) => {
  commit(types.API_REQUEST_START, 'getReturnAuthorization')

  return new Promise((resolve, reject) => {
    User.getReturnAuthorization(number)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getReturnAuthorization')
        dispatch('returnAuthorizations/getResourceFromRelationship', response, { root: true })
        dispatch('productVariants/receiveResourcesFromRelationships', response, { root: true })
        dispatch('reimbursements/receiveResourcesFromRelationships', response, { root: true })
        dispatch('orders/receiveRefundsFormRelationship', response, { root: true })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getReturnAuthorization,
            ref: {
              dispatch,
              commit,
            },
            params: number,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const importUsers = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'importUsers')

  return new Promise((resolve, reject) => {
    User.importUsers(formData)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'importUsers')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: importUsers,
            ref: {
              dispatch,
              commit,
            },
            params: formData,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchNotes = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchNotes')

  return new Promise((resolve, reject) => {
    model
      .fetchNotes(options)
      .then((response) => {
        dispatch('notes/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchNotes')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchNotes,
            ref: {
              dispatch,
              commit,
            },
            params: {
              model,
              options,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const addNote = ({ dispatch, commit }, { model, note }) => {
  commit(types.API_REQUEST_START, 'addNote')

  return new Promise((resolve, reject) => {
    model
      .addNote(note)
      .then((response) => {
        dispatch('notes/addResourceFromRelationship', response, {
          root: true,
        })
        commit(types.API_REQUEST_SUCCESS, 'addNote')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: addNote,
            ref: {
              dispatch,
              commit,
            },
            params: {
              model,
              note,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchTransactions = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchTransactions')

  return new Promise((resolve, reject) => {
    model
      .fetchTransactions(options)
      .then((response) => {
        commit(types.FETCH_TRANSACTIONS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchTransactions,
            ref: {
              dispatch,
              commit,
            },
            params: {
              model,
              options,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const addTransaction = ({ dispatch, commit }, { model, transaction }) => {
  commit(types.API_REQUEST_START, 'addTransaction')

  return new Promise((resolve, reject) => {
    model
      .addTransaction(transaction)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'addTransaction')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: addTransaction,
            ref: {
              dispatch,
              commit,
            },
            params: {
              model,
              transaction,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const toggleArrearFlag = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleArrearFlag')

  return new Promise((resolve, reject) => {
    model
      .toggleArrearFlag()
      .then((response) => {
        commit(types.UPDATE_USER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleArrearFlag,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const toggleSuspended = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleSuspended')

  return new Promise((resolve, reject) => {
    model
      .toggleSuspended()
      .then((response) => {
        commit(types.UPDATE_USER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleSuspended,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_USERS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_USER_SUCCESS, response)

    resolve(response)
  })
}
