const TAX_RATES = {
  TWD: {
    taxRate: 0.05,
  },
}

export default class B2BTaxCalculator {
  /**
   *Creates an instance of TaxCalculator.
   * @param {Money} amount
   * @param {string} [currency='TWD']
   * @memberof TaxCalculator
   */
  constructor(amount, currency = 'TWD') {
    if (!amount.constructor.name === 'Money') throw new Error('amount must be an instance of Money')
    if (!TAX_RATES[currency]) throw new Error('currency no supported.')

    this.amount = amount
    this.taxRate = TAX_RATES[currency].taxRate
  }

  compute() {
    const postTax = this.amount.multiply(1 + this.taxRate)

    return {
      preTax: this.amount,
      postTax: postTax,
      taxAmount: postTax.substract(this.amount),
    }
  }

  /**
   * compute tax
   *
   * @static
   * @param {Money} amount
   * @param {string} [currency='TWD']
   * @returns {Object} an Object contain result of preTax, postTax, taxAmount.
   * @memberof TaxCalculator
   */
  static compute(amount, currency = 'TWD') {
    return new this(amount, currency).compute()
  }
}
