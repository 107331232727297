import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'reimbursements',
  attributes: [
    'id',
    'number',
    'state',
    'total',
    'created_at',
    'updated_at',
    'final_refund_reply',
    'final_refund_reply_subject',
    'final_exchange_reply',
    'final_exchange_reply_subject',
    'order',
    'return_authorization',
  ],
  editableAttributes: [
    'final_refund_reply',
    'final_refund_reply_subject',
    'final_exchange_reply',
    'final_exchange_reply_subject',
  ],
}

export default class Reimbursement extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  perform() {
    return axios.post(`${this.apiBasePath()}/${this.id}/perform`, this.requestBody())
  }

  // extra methods or helpers here...
  get stateType() {
    return {
      'is-warning': this.state === 'pending',
      'is-info': this.state === 'waiting',
      'is-success': this.state === 'reimbursed',
      'is-danger': this.state === 'error_found',
    }
  }

  get isPerformAble() {
    return this.state === 'pending'
  }
}
