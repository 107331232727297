<template lang="pug">

.vc-return-authorization-form(v-if="returnItems && returnItems.length > 0")
  h3.form-title {{ modelNameLocaleText('return_authorization') }}
  .form-body
    h4.section-title {{ attributeLocaleText('return_authorization', 'selected_items') }}
    template(v-if="errors.get('items')")
      .error-unit(v-for="error in errors.get('items')")
        p {{ error }}
    .return-item-unit(v-for="returnItem in returnItems"
                      :key="returnItem.shipment_item_id")
      .return-item-info
        .group
          .group.option-and-thumb
            .product-thumb
              img.img(:src="returnItem.image")
          .group.name-and-unit-price
            .product-name
              .name {{ returnItem.name }}
            //- .unit-price
            //-   label.label {{ attributeLocaleText('order/item', 'price') }}
            //-   .price {{ returnItem.unit_price.toLocaleString() }}
        .group
          .quantity-info
            label.label {{ attributeLocaleText('return_authorization/item', 'return_quantity') }}
            .quantity {{ returnItem.return_quantity }}
      .return-item-options
        b-field(:label="attributeLocaleText('return_authorization/item', 'description')")
          b-input(type="textarea"
                  placeholder="e.g. Jone Doe"
                  v-model="returnItem.description"
                  required)
        label.label {{ attributeLocaleText('return_authorization/item', 'reimbursement_type') }}
        b-field
          b-radio(v-model="returnItem.reimbursement_type"
                  native-value="refund")
            .icon
              i.fa.fa-money
            span {{ enumLocaleText('return_authorization/item', 'reimbursement_type', 'refund') }}
          b-radio(v-model="returnItem.reimbursement_type"
                  native-value="exchange")
            .icon
              i.fa.fa-exchange
            span {{ enumLocaleText('return_authorization/item', 'reimbursement_type', 'exchange') }}
        b-field.image-upload-block
          b-upload(v-model="returnItem.images"
                   multiple
                   drag-drop
                   accept="image/*")
            .section
              .content.has-text-centered
                b-icon(icon="upload"
                       size="is-medium")
                p {{ messageLocaleText('help.drop_your_files_here_or_click_to_upload') }}
          .image-list
            .image-unit(v-for="(image, index) in returnItem.images")
              .image(:style="`background-image: url(${readFile(image)})`")
                .remove-button(@click="deleteFileFrom(returnItem.images, index)")
                  i.fa.fa-close

    //- h4.section-title {{ attributeLocaleText('return_authorization', 'reason_id') }}
    //- b-select(:placeholder="actionLocaleText('choose_one')"
    //-          v-model="form.reason_id"
    //-          expanded)
    //-   option(v-for="reason in reasons"
    //-          :key="reason.id"
    //-          :value="reason.id") {{ reason.name }}

    //- b-field(:label="attributeLocaleText('return_authorization', 'note')"
    //-         label-position="on-border")
    //-   b-input(v-model="form.note"
    //-           type="textarea")



</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    errors() {
      return this.$store.getters['users/errors']
    },

    returnItems() {
      return this.form.items
    },

    reasons() {
      return this.$store.getters['returnAuthorizations/allReasons']
    },
  },

  created() {
    this.$store.dispatch('returnAuthorizations/fetchReasons', {
      sort: '-created_at',
    })
  },
  // mounted() {},
  methods: {
    readFile(file) {
      return URL.createObjectURL(file)
    },

    deleteFileFrom(imagesArray, index) {
      imagesArray.splice(index, 1)
    },
  },
}
</script>
