<template lang="pug">

.vc-sales-event-purchase-button.option-button
  .button.is-odd(@click="showVariantsSelector")
    .icon
      i.fa.fa-shopping-cart
    span.text {{ actionLocaleText('select_goods') }}

</template>

<script>
import VariantsSelector from './variants-selector.vue'

export default {
  components: {
    VariantsSelector,
  },
  // mixins: [],
  props: {
    salesEvent: {
      type: Object,
      required: true,
    },

    product: {
      type: Object,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    showVariantsSelector() {
      this.$buefy.modal.open({
        parent: this,
        width: 380,
        component: VariantsSelector,
        props: {
          salesEvent: this.salesEvent,
          product: this.product,
        },
      })
    },
  },
}
</script>
