import productImpressionMixin from '@applicationComponents/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '@sharedMixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ProductCard from '@applicationComponents/product/card.vue'
import DistributionBaseList from '@applicationComponents/distribution_base/list.vue'
import OddPagination from '@applicationComponents/common/odd-pagination.vue'
import BannerSlideshow from '@applicationComponents/brand/slideshow.vue'
import Brand from '@models/brand.js'

export default {
  name: 'brand-show-page-container',

  components: {
    ProductCard,
    DistributionBaseList,
    OddPagination,
    BannerSlideshow,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin, productImpressionMixin],

  // props: {},

  data() {
    return {
      currentTab: 0,
      tabs: ['description', 'products', 'distribution_bases'],
      brand: null,
      resourceType: 'products',
      pageSize: 16,
      sortField: 'created_at',
      availableFilters: [],
      isInitializing: true,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['brands/isLoading']
    },

    products() {
      return this.$store.getters['products/all']
    },

    distributionBases() {
      return this.$store.getters['distributionBases/all']
    },

    additionalOptions() {
      return {
        tab: this.currentTab,
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    listLocation() {
      return `Brand - ${this.products[0].brand_name}`
    },
  },

  // created() {},

  mounted() {
    this.brand = new Brand({
      id: this.$el.dataset.brand,
    })
    this.fetchingInitialData()
    this.$store
      .dispatch('brands/fetchDistributionBases', {
        model: this.brand,
        options: {
          sort: 'location_id',
        },
      })
      .then((_) => {
        this.isInitializing = false
      })
  },

  methods: {
    checkAdditionalOptionsFromUrl() {
      return {
        tab: this.currentQueryString['tab'] || this.currentTab,
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      this.currentTab = parseInt(options.tab)
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      result += `&tab=${options.tab}`

      return result
    },

    tabChangeHandler() {
      this.updateQueryString(this.currentOptions)
    },

    fetchData(options) {
      this.currentPage = options.pageNumber
      this.$store.dispatch(`brands/fetchProducts`, {
        model: this.brand,
        options,
      })
    },
  },
}
