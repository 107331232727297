<template lang="pug">

.vc-product-hot-products
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  product-card(v-for="(product, index) in hotProducts"
               :key="'hot' + product.id"
               :product="product"
               :index="index"
               :list-location="listLocation")

</template>

<script>
import ProductCard from './card.vue'

export default {
  components: {
    ProductCard,
  },
  // mixins: [],
  props: {
    productsCount: {
      type: Number,
      required: false,
      default: () => {
        return 8
      },
    },
  },

  data() {
    return {
      hotProductIds: null,
      isLoading: true,
    }
  },

  computed: {
    hotProducts() {
      if (this.hotProductIds)
        return this.$store.getters['products/all'].filter((product) => this.hotProductIds.includes(product.id))
    },

    icon() {
      return fireIcon
    },

    listLocation() {
      return 'Hot Products'
    },
  },

  created() {
    this.$store.dispatch('products/hotProducts').then((response) => {
      this.hotProductIds = response.data.data.map((product) => product.id)
      this.isLoading = false
    })
  },
  // mounted() {},
  // methods: {}
}
</script>
