<template lang="pug">

.vc-return-authorization-summary-unit
  date-and-number(:return-authorization="returnAuthorization")
  state-list(:return-authorization="returnAuthorization")

  //- .return-price-info
  //-   span.title
  //-     span {{ attributeLocaleText('return_authorization', 'total_amount') }}
  //-     span.items-count {{ copyLocaleText('total_items_count', { count: returnAuthorization.items.length }) }}
  //-   span.price(data-currency="TWD") {{ toMoney(returnAuthorization.total_amount).format() }}

  .options
    a.button.is-outlined.is-default(:href="`/user/return_authorizations/${returnAuthorization.number}`") {{ actionLocaleText('detail_info') }}
    button.button.is-outlined.is-default(v-if="returnAuthorization.state === 'pending'"
                                         @click="confirmCancel") {{ actionLocaleText('cancel') }}

</template>

<script>
import ActionConfirmService from '@services/action_confirm_service'
import DateAndNumber from './date-and-number.vue'
import StateList from './state-list.vue'

export default {
  components: {
    DateAndNumber,
    StateList,
  },
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    confirmCancel() {
      new ActionConfirmService().confirm(this.cancel)
    },

    cancel() {
      this.$store.dispatch('returnAuthorizations/cancel', this.returnAuthorization)
    },
  },
}
</script>
