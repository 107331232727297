export default class ExtraQueryStringParseService {
  static call(options) {
    let result = `${this.tag(options)}${this.category(options)}`

    return result
  }

  static tag(options) {
    let tag = options.tag

    if (tag) return `&tag=${tag}`

    return ''
  }

  static category(options) {
    let category_id = options.category_id

    if (category_id) return `&category_id=${category_id}`

    return ''
  }
}
