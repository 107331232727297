import axios from 'axios'
import moment from 'moment'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'sales_events',
  attributes: [
    'id',
    'created_at',
    'description',
    'ended_at',
    'is_enabled',
    'name',
    'started_at',
    'updated_at',
    'product_ships',
    'cover',
    'summary',
    'is_hidden',
    'token',
    'category_name',
    'category',
    'is_hide_price',
  ],
  editableAttributes: [
    'name',
    'started_at',
    'ended_at',
    'is_enabled',
    'description',
    'uploaded_attachment_ids',
    'summary',
    'category_id',
    'is_hide_price',
  ],
}

export default class SalesEvent extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.category_id) queryString += `&category_id=${options.category_id}`
    if (options.by_default_usages)
      options.by_default_usages.map((usage) => {
        queryString += `&by_default_usages[]=${usage}`
      })

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  updateCover(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/cover`, formData)
  }

  removeCover() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/cover`)
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }

  hide() {
    return axios.put(`${this.apiBasePath()}/${this.id}/hide`)
  }

  hidePrice() {
    return axios.put(`${this.apiBasePath()}/${this.id}/hide_price`)
  }

  revokeToken() {
    return axios.post(`${this.apiBasePath()}/${this.id}/revoke_token`)
  }

  createProductShips(productIds) {
    const requestBody = {
      data: {
        type: 'sales-event-product-ships',
        attributes: {
          product_ids: productIds,
        },
      },
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/product_ships`, requestBody)
  }

  updateProductShips(productShips) {
    const requestBody = {
      data: {
        type: 'sales-event-product-ships',
        attributes: {
          product_ships: productShips,
        },
      },
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/product_ships`, requestBody)
  }

  deleteProductShips(productship) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/product_ships/${productship.id}`)
  }

  fetchProducts(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products?${FetchingDataOptionsService.call(options)}`)
  }

  findProduct(productId) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products/${productId}`)
  }

  // extra methods or helpers here...

  displayPeriod(options = {}) {
    const defaults = {
      format: 'YYYY MMMDD HH:mm:ss',
      multipleLine: false,
    }

    options = Object.assign({}, defaults, options)

    if (this.started_at && this.ended_at) {
      if (options.multipleLine)
        return `${moment.unix(this.started_at).format(options.format)}<br>${moment
          .unix(this.ended_at)
          .format(options.format)}`

      return `${moment.unix(this.started_at).format(options.format)} ~ ${moment
        .unix(this.ended_at)
        .format(options.format)}`
    } else {
      return '∞'
    }
  }

  isLive() {
    if (!this.is_enabled) return false

    const currentTime = moment()

    if (this.started_at && this.ended_at) {
      return currentTime.isBetween(moment.unix(this.started_at), moment.unix(this.ended_at))
    } else {
      return true
    }
  }

  hasCover() {
    return this.cover && this.cover.url !== null
  }
}
