import * as types from './mutation-types'
import SiteConfig from '@models/site_config'

export const fetchConfigs = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchConfigs')

  return new Promise((resolve, reject) => {
    SiteConfig.all()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchConfigs,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchWebsitePreferences = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchWebsitePreferences')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchWebsitePreferences()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchWebsitePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchStorePreferences = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchStorePreferences')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchStorePreferences()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStorePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchShippingFeeConfigurations = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchShippingFeeConfigurations')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchShippingFeeConfigurations()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShippingFeeConfigurations,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateWebsitePreferences = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateWebsitePreferences')

  return new Promise((resolve, reject) => {
    model
      .updateWebsitePreferences()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateWebsitePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateStorePreferences = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateStorePreferences')

  return new Promise((resolve, reject) => {
    model
      .updateStorePreferences()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateStorePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateShippingFeeConfigurations = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateShippingFeeConfigurations')

  return new Promise((resolve, reject) => {
    model
      .updateShippingFeeConfigurations()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        model.errors.record(errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateShippingFeeConfigurations,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateMobileQuickLinks = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateMobileQuickLinks')

  return new Promise((resolve, reject) => {
    model
      .updateMobileQuickLinks()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateMobileQuickLinks,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateSiteContacts = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateSiteContacts')

  return new Promise((resolve, reject) => {
    model
      .updateSiteContacts()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSiteContacts,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchMobileQuickLinks = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchMobileQuickLinks')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchMobileQuickLinks()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchMobileQuickLinks,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateRmaConfig = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateRmaConfig')

  return new Promise((resolve, reject) => {
    model
      .updateRmaConfig()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateRmaConfig,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateHolidayDatabase = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateHolidayDatabase')

  return new Promise((resolve, reject) => {
    SiteConfig.updateHolidayDatabase()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateHolidayDatabase,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchSiteIndexConfig = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchSiteIndexConfig')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchSiteIndexConfig()
      .then((response) => {
        commit(types.GET_SITE_INDEX_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchSiteIndexConfig,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateSiteIndexConfig = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateSiteIndexConfig')

  return new Promise((resolve, reject) => {
    model
      .updateSiteIndexConfig()
      .then((response) => {
        commit(types.UPDATE_SITE_INDEX_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSiteIndexConfig,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateDataPosition = ({ dispatch, commit }, data) => {
  commit(types.API_REQUEST_START, 'updateDataPosition')

  return new Promise((resolve, reject) => {
    SiteConfig.updateDataPosition(data)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'updateDataPosition')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateDataPosition,
            ref: {
              dispatch,
              commit,
            },
            params: data,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}
