import ReturnAuthorization from '@models/return_authorization'
import ReturnAuthorizationReason from '@models/return_authorization_reason.js'
import ReturnAuthorizationItem from '@models/return_authorization_item.js'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new ReturnAuthorization(state.entities[id]))
}

/**
 * DOCUMENT THIS
 *
 * @returns {Object} something
 */
export const allReasons = (state) => {
  return state.reasonResult.map((id) => new ReturnAuthorizationReason(state.reasonEntities[id]))
}

export const allItems = (state) => {
  return state.itemResult.map((id) => new ReturnAuthorizationItem(state.itemEntities[id]))
}

export const find = (state) => (id) => {
  return new ReturnAuthorization(state.entities[id])
}

export const findByNumber = (state) => (number) => {
  const result = Object.values(state.entities).find((rma) => rma.number === number)

  if (result) return new ReturnAuthorization(result)
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
