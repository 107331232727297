import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_transactions',
  attributes: ['id', 'amount', 'balance', 'notes', 'reason', 'created_at', 'updated_at'],
  editableAttributes: ['amount', 'action', 'note'],
}

export default class UserTransaction extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
