<template lang="pug">

.vc-order-shipping-and-payment-method.odd-container.has-padding
  b-loading(:active.sync="isLoading")

  .form-container
    .form-column
      .section-label
        span {{ copyLocaleText('contact_infomation') }}
      div(v-if="currentUserData.isDataLoaded()")
        .store-info-card
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'customer_code') }}
            .info.is-family-code {{ currentUserData.profile.customer_code }}
          //- .info-unit
          //-   label.label {{ attributeLocaleText('user/profile', 'i_coach_code') }}
          //-   .info.is-family-code {{ currentUserData.profile.i_coach_code }}
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'store_phone') }}
            .info {{ currentUserData.profile.store_phone }}
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'store_address') }}
            .info.is-full {{ currentUserData.profile.store_address }}
          .info-unit
            .label {{ attributeLocaleText('user/profile', 'franchisee_name') }}
            .info {{ currentUserData.profile.franchisee_name }}
          .info-unit
            .label {{ attributeLocaleText('user/profile', 'franchisee_mobile') }}
            .info {{ currentUserData.profile.franchisee_mobile }}
          .info-unit(v-if="currentUserData.profile.franchisee_name_2")
            .label {{ attributeLocaleText('user/profile', 'franchisee_name_2') }}
            .info {{ currentUserData.profile.franchisee_name_2 }}
          .info-unit(v-if="currentUserData.profile.franchisee_mobile_2")
            .label {{ attributeLocaleText('user/profile', 'franchisee_mobile_2') }}
            .info {{ currentUserData.profile.franchisee_mobile_2 }}
          .info-unit
            .label {{ attributeLocaleText('user/profile', 'company_name') }}
            .info {{ currentUserData.profile.company_name }}
          .info-unit
            .label {{ attributeLocaleText('user/profile', 'company_ein') }}
            .info {{ currentUserData.profile.company_ein }}
          .info-unit
            .label {{ attributeLocaleText('user/profile', 'company_address') }}
            .info.is-full {{ currentUserData.profile.company_address }}

        .store-info-card
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'receiver_name') }}
            .info {{ currentUserData.profile.receiver_name }}
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'receiver_phone') }}
            .info
              .icon
                i.fa.fa-mobile
              span {{ currentUserData.profile.receiver_mobile }}
            .info
              .icon
                i.fa.fa-phone
              span {{ currentUserData.profile.receiver_phone }}
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'receiver_address') }}
            .info.is-full {{ currentUserData.profile.receiver_address }}

        .store-info-card
          .info-unit
            label.label {{ attributeLocaleText('user/profile', 'contact_emails') }}
            .contact-email(v-for="(email, index) in currentUserData.profile.contact_emails")
              span(:data-index="index + 1") {{ email }}

    .form-column.-clean
      //- grouped price detail
      .price-detail-unit(v-for="productType in productTypeList"
                        :key="productType")
        .section-label
          span {{ `${systemLocaleText(`product_categories.${productType}`)} ${modelNameLocaleText('order')}` }}
        calculated-price-detail(:order="order"
                                :cart-items="groupedItems[productType].filter(item=> item.use_type !== 'gift' )"
                                :product-type="productType")
      //- notes
      .section-label
        span {{ attributeLocaleText('order', 'notes') }}
      .form-section
        b-field(:type="errors.errorClassAt('notes')"
                :message="errors.get('notes')")
          b-input(type="textarea"
                  v-model="form.notes"
                  @input="formEditedHandler(); errors.clear('notes')")

      //- aggrements
      .section-label
        span {{ copyLocaleText('user_aggrement_and_notes') }}
      .form-section.checkboxes
        .field
          b-checkbox(v-model="form.is_agree_with_term_of_service"
                     size="is-small"
                     type="is-odd")
            span(v-html="copyLocaleText('i_agree_with_terms_of_service_and_privacy_policy')")

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(@click="placeOrder"
                                           :disabled="!form.is_agree_with_term_of_service"
                                           :class="{ 'is-loading': isLoading }") {{ actionLocaleText('next_step') }}

</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import Form from 'odd-form_object'
import CalculatedPriceDetail from './calculated-price-detail.vue'
import BackToStoreButton from './back-to-store-button.vue'
import ActionConfirmService from '@services/action_confirm_service.js'

export default {
  components: {
    CalculatedPriceDetail,
    BackToStoreButton,
  },

  mixins: [checkoutFlowMixin],
  // props: {},
  data() {
    return {
      isFormEdited: false,
      form: new Form(Object.assign(this.order, this.order.formTemplate())),
      isEmailChecking: false,
      isEmailChanged: false,
    }
  },

  computed: {
    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    },
  },

  watch: {
    'form.payment_method_id': {
      handler() {
        this.errors.clear('payment_method_id')
      },
    },

    'form.email': {
      handler(newEmail) {
        this.isEmailChanged = true
      },
    },
  },

  created() {
    if (!this.currentUserData.isDataLoaded()) this.$store.dispatch('users/find', this.currentUser.id)
  },

  mounted() {
    if (this.currentUser.isGuest()) this.isAgreeToJoinMembershipAfterCheckout = true
    if (this.order.payment.id) this.form.payment_method_id = this.paymentMethodFromOrderPayment.id
    if (!this.form.notes) this.form.notes = this.__notesTemplate()
    this.form.email = this.order.email
    this._tryFetchPaymentMethods()
    this._trackCheckoutProgress()
  },

  methods: {
    checkForExistedMember() {
      if (this.isUserSignedIn || !this.isEmailChanged) return

      this.isEmailChecking = true
      this.$store
        .dispatch('users/check', this.form.email)
        .then((result) => {
          if (result.data.data.result) this._showLoginRequestAlert()
        })
        .finally((_) => {
          this.isEmailChecking = false
          this.isEmailChanged = false
        })
    },

    _showLoginRequestAlert() {
      new ActionConfirmService({
        type: 'question',
        title: this.messageLocaleText('confirmations.would_you_like_to_login_as_a_member'),
        text: this.messageLocaleText(
          'help.if_place_order_as_a_guest_you_will_need_to_check_email_for_tracking_your_order_status'
        ),
        confirmButtonText: this.actionLocaleText('shopping_as_a_member'),
        cancelButtonText: this.actionLocaleText('shopping_as_a_guest'),
        reverseButtons: true,
      }).confirm(this._goToLoginPage)
    },

    _goToLoginPage() {
      Turbolinks.visit('/user/login')
    },

    __notesTemplate() {
      let types = this.productTypeList.map((productType) => {
        return `【${this.systemLocaleText(`product_categories.${productType}`)}${this.modelNameLocaleText(
          'order'
        )}】:\n\n`
      })

      return types.join('')
    },

    formEditedHandler() {
      this.isFormEdited = true
    },

    placeOrder() {
      if (!this.form.is_agree_with_term_of_service) return

      if (this.order.order_state === 'items_confirmed' || this.isFormEdited) {
        this.cartService.placeOrder(this.form.sync()).catch((errors) => {
          if (errors.response.status === 500) this.$emit('update:currentStep', 1)
        })
      } else {
        this.$emit('update:currentStep', 3)
      }

      this._scrollToTop()
    },
  },
}
</script>
