<template lang="pug">

.vc-comment-unit(:class="{ 'is-reply': comment.is_reply }")
  .creator-info
    .avatar
      img(:src="comment.avatarUrl()")
    .name
      span {{ dispalyName }}
  .comment-content
    div(v-html="simpleFormat(comment.content)")
    .attachment-list(v-if="comment.hasAttachment")
      .attachment-unit(v-for="attachment in comment.attachments")
        img(:src="attachment.file.thumb.url"
            @click="pictureModal(attachment)")
    .created-at(:title="createdOrEditedOnDatetime") {{ createdOrEditedOnText }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    dispalyName() {
      if (this.comment.is_reply) {
        return this.copyLocaleText('service_center')
      } else {
        return 'Me'
      }
    },

    createdOrEditedOnText() {
      if (this.comment.isEdited()) {
        return this.messageLocaleText('edited_on', { datetime: this.timeAgoLocaleText(this.comment.updated_at) })
      } else {
        return this.timeAgoLocaleText(this.comment.created_at)
      }
    },

    createdOrEditedOnDatetime() {
      if (this.comment.isEdited()) {
        return this.dateTimeLocaleText(this.comment.updated_at)
      } else {
        return this.dateTimeLocaleText(this.comment.created_at)
      }
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    pictureModal(attachment) {
      this.$buefy.modal.open(`
        <a href="${attachment.file.url}" target="_blank">
          <p class="image">
            <img src="${attachment.file.url}">
          </p>
        </a>
      `)
    },
  },
}
</script>
