import FbMessenger from '@applicationComponents/common/fb-messenger.vue'
import debounce from 'lodash.debounce'
import logoV from '../../images/logo-v.png'

const MOBILE_BREAK_POINT = 769

export default {
  name: 'site-footer-container',

  components: {
    FbMessenger,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      windowWidth: 0,
      firstOpened: false,
      secondOpened: false,
      thirdOpened: false,
      rootCategory: undefined,
    }
  },

  computed: {
    logoV() {
      return logoV
    },

    isMobile() {
      return this.$store.getters['windowWidth'] < MOBILE_BREAK_POINT
    },

    allCategories() {
      let rootCategory = this.rootCategory

      if (!rootCategory) return []

      return this.$store.getters['categorySystems/allCategories']().filter(
        (category) => category.lft >= rootCategory.lft && category.rgt <= rootCategory.rgt
      )
    },

    // 可用的「用途」選項
    usages() {
      return this.allCategories
        .filter((category) => category.depth === 1)
        .filter((category) => category.default_usage !== 'retail') // 不顯示零售商品
    },
  },

  created() {
    if (this.usages.length > 0) return
    this.$store
      .dispatch(`categorySystems/fetchCategoriesByType`, {
        type: 'product',
        sort: 'created_at',
        noReplace: true,
      })
      .then((response) => {
        let rootCategoryId = response.data.data.find((categoryRawData) => categoryRawData.attributes.depth === 0).id
        this.rootCategory = this.$store.getters['categorySystems/findCategory'](rootCategoryId)
      })
  },

  methods: {
    childrenOf(category) {
      return this.$store.getters['categorySystems/childrenOf'](category)
    },

    visitProductIndexWithCategroy(category) {
      Turbolinks.visit(`/products?category_id=${category.id}`)
    },

    toggleList(target) {
      this[target] = !this[target]
    },
  },
}
