<template lang="pug">

.vc-dashboard-user-profile-form
  .store-credit-wrapper
    label.label
      span {{ attributeLocaleText('user', 'store_credit') }}
    .credit(data-currency="TWD") {{ toMoney(user.store_credit).format() }}
    .transaction-list-button.tag.is-info.is-rounded(@click="showTransactionList")
      .icon
        i.fa.fa-file-text-o
      span {{ modelNameLocaleText('user/transaction') }}

  hr

  .section-label
    .icon
      i.fa.fa-truck
    span {{ copyLocaleText('receiver_info') }}

  .form-section.has-sub-fields
    b-field(:label="attributeLocaleText('user/profile', 'receiver_name')"
            :type="errors.errorClassAt('receiver_name')"
            :message="errors.get('receiver_name')")
      b-input(v-model="form.profile.receiver_name"
              autocomplete="name"
              required
              @input="errors.clear('receiver_name')")

    b-field(:label="attributeLocaleText('user/profile', 'receiver_mobile')"
            :type="errors.errorClassAt('receiver_mobile')"
            :message="errors.get('receiver_mobile')")
      b-input(v-model="form.profile.receiver_mobile"
              type="tel"
              autocomplete="tel"
              required
              @input="errors.clear('receiver_mobile')")

    b-field(:label="attributeLocaleText('user/profile', 'receiver_phone')"
            :type="errors.errorClassAt('receiver_phone')"
            :message="errors.get('receiver_phone')")
      b-input(v-model="form.profile.receiver_phone"
              type="tel"
              autocomplete="tel"
              @input="errors.clear('receiver_phone')")

    b-field.is-full(:label="attributeLocaleText('user/profile', 'receiver_address')"
                    :type="errors.errorClassAt('receiver_address')"
                    :message="errors.get('receiver_address')")
      b-input(v-model="form.profile.receiver_address"
              required
              @input="errors.clear('receiver_address')")

  .section-label
    .icon
      i.fa.fa-address-book-o
    span {{ copyLocaleText('franchisee_info') }}

  .form-section.has-sub-fields
    b-field(:label="attributeLocaleText('user/profile', 'franchisee_name')"
            :type="errors.errorClassAt('franchisee_name')"
            :message="errors.get('franchisee_name')")
      b-input(v-model="form.profile.franchisee_name"
              @input="errors.clear('franchisee_name')")

    b-field(:label="attributeLocaleText('user/profile', 'franchisee_mobile')"
            :type="errors.errorClassAt('franchisee_mobile')"
            :message="errors.get('franchisee_mobile')")
      b-input(v-model="form.profile.franchisee_mobile"
              type="tel"
              autocomplete="tel"
              @input="errors.clear('franchisee_mobile')")

    b-field(:label="attributeLocaleText('user/profile', 'company_name')"
            :type="errors.errorClassAt('company_name')"
            :message="errors.get('company_name')")
      b-input(v-model="form.profile.company_name"
              autocomplete="organization"
              @input="errors.clear('company_name')")

    b-field(:label="attributeLocaleText('user/profile', 'company_ein')"
            :type="errors.errorClassAt('company_ein')"
            :message="errors.get('company_ein')")
      b-input(v-model="form.profile.company_ein"
              type="tel"
              @input="errors.clear('company_ein')")

    b-field(:label="attributeLocaleText('user/profile', 'franchisee_name_2')"
            :type="errors.errorClassAt('franchisee_name_2')"
            :message="errors.get('franchisee_name_2')")
      b-input(v-model="form.profile.franchisee_name_2"
              @input="errors.clear('franchisee_name_2')")

    b-field(:label="attributeLocaleText('user/profile', 'franchisee_mobile_2')"
            :type="errors.errorClassAt('franchisee_mobile_2')"
            :message="errors.get('franchisee_mobile_2')")
      b-input(v-model="form.profile.franchisee_mobile_2"
              type="tel"
              autocomplete="tel"
              @input="errors.clear('franchisee_mobile_2')")

    b-field.is-full(v-for="(email, index) in form.profile.contact_emails"
                    :key="index"
                    :label="`${attributeLocaleText('user/profile', 'contact_emails')} ${index + 1}`"
                    :type="errors.errorClassAt('contact_emails')")
      .field.has-addons
        b-input(v-model="form.profile.contact_emails[index]"
                type="email"
                @input="errors.clear('contact_emails')"
                expanded)
        .control(v-if="form.profile.contact_emails.length > 1")
          button.button.is-danger(@click="removeContactEmail(index)")
            .icon
              i.fa.fa-trash-o
        .control(v-if="index === form.profile.contact_emails.length - 1 && form.profile.contact_emails.length < 5")
          button.button.is-info(@click="addContactEmail")
            .icon
              i.fa.fa-plus

    p.help.is-danger(v-for="message in errors.get('contact_emails')") {{ message }}

  button.button.is-odd.is-block(@click="submitForm"
                                    :class="{'is-loading': isLoading}")
    | {{ actionLocaleText('submit') }}

</template>

<script>
import UserTransactionList from './user-transaction-list.vue'
import Form from 'odd-form_object'

export default {
  components: {
    UserTransactionList,
  },
  // mixins: [],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: new Form(this.user),
    }
  },

  computed: {
    errors() {
      return this.form.model.errors
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },
  },
  // created() {},
  mounted() {
    this._initializeContactEmails()
  },

  methods: {
    showTransactionList() {
      this.$buefy.modal.open({
        parent: this,
        component: UserTransactionList,
        props: {
          user: this.user,
        },
      })
    },

    _initializeContactEmails() {
      if (this.form.profile.contact_emails.length === 0) this.addContactEmail()
    },

    addContactEmail() {
      this.form.profile.contact_emails.push(undefined)
    },

    removeContactEmail(index) {
      this.form.profile.contact_emails.splice(index, 1)
    },

    submitForm() {
      this.$store.dispatch('users/updateProfile', this.form.sync()).then(() => {
        return this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText('resource_updated_successfully', {
            resource: this.attributeLocaleText('user', 'profile'),
          }),
        ])
      })
    },
  },
}
</script>
