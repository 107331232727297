import CalculatedPriceDetail from '../order/calculated-price-detail.vue'
import PriceDetail from '../order/price-detail.vue'

const PREORDER_TYPE_SUFFIX = '_preorder'

export default {
  components: {
    CalculatedPriceDetail,
    PriceDetail,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    cartItems: {
      type: Array,
      required: true,
    },

    cartService: {
      type: Object,
      required: true,
    },

    currentStep: {
      type: Number,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading'] || this.$store.getters['orders/isLoading']
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    productTypeList() {
      return this.cartItems
        .map((item) => {
          let result = item.product_type

          if (item.isPreorderItem) result += PREORDER_TYPE_SUFFIX

          return result
        })
        .filter((element, index, array) => array.indexOf(element) === index) // uniq
        .sort()
    },

    groupedItems() {
      let result = {}

      this.productTypeList.forEach((type) => {
        if (type.includes(PREORDER_TYPE_SUFFIX)) {
          const productType = type.replace(PREORDER_TYPE_SUFFIX, '')

          result[type] = this.cartItems.filter((item) => item.product_type === productType && item.isPreorderItem)
        } else {
          result[type] = this.cartItems.filter((item) => item.product_type === type && !item.isPreorderItem)
        }
      })

      return result
    },

    priceDetailComponent() {
      return 'calculated-price-detail'
    },

    paymentMethodFromOrderPayment() {
      if (this.order.payment) return this.$store.getters['orderPayments/find'](this.order.payment.id).payment_method
    },

    paymentMethods() {
      return this.$store.getters['paymentMethods/allEnabled']
    },

    orderPayment() {
      return this.order.payment.id && this.$store.getters['orderPayments/find'](this.order.payment.id)
    },

    orderInvoice() {
      return this.orderPayment && this.$store.getters['orders/findInvoice'](this.orderPayment.invoice.id)
    },
  },

  methods: {
    _tryFetchPaymentMethods() {
      if (this.paymentMethods.length === 0) {
        return this.$store.dispatch('paymentMethods/all')
      } else {
        return Promise.resolve(true)
      }
    },

    _scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    },

    _trackCheckoutProgress() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:setAction',
            'checkout',
            {
              step: this.currentStep,
            },
          ],
          [
            'send',
            {
              hitType: 'pageview',
              page: `/cart/step${this.currentStep}`,
            },
          ],
        ])
        .then((_) => {
          const stepMapForPixel = [
            null,
            'InitiateCheckout', // Step 1
            'AddPaymentInfo', // Step 2
            null,
          ]
          const operation = stepMapForPixel[this.currentStep]

          if (!operation) return
          this.$store.dispatch('pixelOperation', [
            [
              'track',
              operation,
              {
                currency: 'TWD',
                num_items: this.cartItems.length,
                value: this.toMoney(this.order.total).amount,
              },
            ],
          ])
        })
    },
  },
}
