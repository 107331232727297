<template lang="pug">

.vc-return-authorization-detail-unit

  .content-wrapper
    h4.section-title {{ modelNameLocaleText('return_authorization/item') }}
    .item-unit(v-for="returnItem in returnItems"
              :key="returnItem.shipment_item_id")
      .return-item-info
        .group
          .group.option-and-thumb
            .product-thumb
              img.img(:src="findVariantThumb(returnItem.variant.id)")
          .group.name-and-unit-price
            .product-name
              .state
                span.tag(:class="returnItem.receptionStateType") {{ aasmStateLocaleText('return_authorization/item', 'reception_state', returnItem.reception_state) }}
              .name {{ findProductName(returnItem.variant.id) }}
        .group
          .quantity-info
            label.label {{ attributeLocaleText('return_authorization/item', 'return_quantity') }}
            .quantity {{ returnItem.return_quantity }}
      .return-item-options
        label.label {{ attributeLocaleText('return_authorization/item', 'description') }}
        .description(v-html="simpleFormat(returnItem.description)")

        label.label {{ attributeLocaleText('return_authorization/item', 'reimbursement_type') }}
        .reimbursement-type.tag.is-info
          .icon
            i.fa(:class="{ 'fa-exchange': returnItem.reimbursementType === 'exchange', 'fa-money': returnItem.reimbursementType === 'refund' }")
          span {{ enumLocaleText('return_authorization/item', 'reimbursement_type', returnItem.reimbursementType) }}

        label.label {{ attributeLocaleText('return_authorization/item', 'pictures') }}
        .pictures-wrapper
          .picture-unit(v-for="picture in returnItem.pictures")
            img(:src="picture.image.thumb.url"
                @click="pictureModal(picture)")

    h4.section-title(v-if="reimbursements.length > 0") {{ modelNameLocaleText('reimbursement') }}
    .reimbursement-unit(v-for="reimbursement in reimbursements")
      .info
        label.label {{ attributeLocaleText('reimbursement', 'state')}}
        span.tag(:class="reimbursement.stateType") {{ aasmStateLocaleText('reimbursement', 'state', reimbursement.state) }}
      .columns
        .info.final-reply(v-if="reimbursement.final_refund_reply")
          label.label {{ attributeLocaleText('reimbursement', 'final_refund_reply') }}
          .content(v-html="simpleFormat(reimbursement.final_refund_reply)")
        .info.final-reply(v-if="reimbursement.final_exchange_reply")
          label.label {{ attributeLocaleText('reimbursement', 'final_exchange_reply') }}
          .content(v-html="simpleFormat(reimbursement.final_exchange_reply)")

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    returnItems() {
      return this.$store.getters['returnAuthorizations/allItems'].filter(
        (item) => item.return_authorization.id === this.returnAuthorization.id
      )
    },

    variants() {
      return this.$store.getters['productVariants/all']
    },

    reimbursements() {
      return this.$store.getters['reimbursements/all']
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    findVariantThumb(id) {
      return this.variants.find((variant) => variant.id === id).cover.thumb.url
    },

    findProductName(id) {
      return this.variants.find((variant) => variant.id === id).product_name
    },

    pictureModal(picture) {
      this.$buefy.modal.open(`
        <a href="${picture.image.url}" target="_blank">
          <p class="image">
            <img src="${picture.image.url}">
          </p>
        </a>
      `)
    },
  },
}
</script>
