<template lang="pug">

.vc-product-list-table
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .tools-wrapper
    search-box(@update:search-options="searchOptions.variants_with_master_sku_or_brand_name_or_name_cont = $event"
               @update:search-options-ready="searchOptionsReady = $event")

    sort-button(:sort-field="sortField"
                :sort-order="sortOrder"
                @sort-option-updated="sortOptionUpdatedHandler")

  .decoration-stripe.-one

  product-tag-filter(@update:tag-with="tagWith = $event"
                     @update:tag-ready="tagReady = $event")

  .decoration-stripe.-one.no-margin

  .product-list
    product-card(v-for="(product, index) in products"
                  :product="product"
                  :index="index"
                  :key="product.id"
                  :list-location="listLocation"
                  @ga-impressed="impressedHandler")

  odd-pagination(:total="totalCount"
                 :current.sync="currentPage"
                 :per-page="pageSize"
                 size="is-small"
                 order="is-centered"
                 :rounded="true"
                 @change="onPageChange")
</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '@sharedMixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import SearchBox from './search-box.vue'
import SortButton from './sort-button.vue'
import ProductTagFilter from './tag-filter.vue'
import ProductCard from './card.vue'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    SearchBox,
    SortButton,
    ProductTagFilter,
    ProductCard,
    OddPagination,
  },

  mixins: [productImpressionMixin, backendPaginateFilterSortAndSearchableMixin],

  props: {
    impressionLocation: {
      type: String,
      default: () => {
        return 'Product Index'
      },
    },

    category: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      resourceType: 'products',
      currentPage: 1,
      pageSize: 9,
      sortOrder: 'asc',
      sortField: 'created_at',
      tagReady: false,
      tagWith: [],
      searchOptionsReady: false,
      searchOptions: {
        variants_with_master_sku_or_brand_name_or_name_cont: '',
      },
    }
  },

  computed: {
    listLocation() {
      return this.impressionLocation
    },

    additionalOptions() {
      return {
        category_id: this.category ? this.category.id : null,
        tag: this.tagWith.join(',').replace(/#/g, '%23'),
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    products() {
      return this.$store.getters['products/all']
    },

    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    filterComponentsReady() {
      return this.searchOptionsReady && this.tagReady
    },
  },

  watch: {
    filterComponentsReady() {
      // 等待 tagFilter 與 searchBox 的資訊都從 child components 更新至 parent 時才一併送出資料請求
      this.fetchingInitialData()
    },

    category(value) {
      if (this.filterComponentsReady) this.processDataWithNewQuery()
    },

    tagWith(value) {
      if (this.filterComponentsReady) this.processDataWithNewQuery()
    },

    'searchOptions.variants_with_master_sku_or_brand_name_or_name_cont': {
      handler: function (value) {
        if (this.filterComponentsReady) this.processDataWithNewQuery()
      },
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    sortOptionUpdatedHandler(field, order) {
      this.onSort(field, order)
    },

    fetchData(options) {
      this.currentPage = options.pageNumber

      if (this.category) {
        options.sub_categories_included = true

        this.$store.dispatch('categorySystems/fetchCategoryProducts', {
          model: this.category,
          options,
        })
      } else {
        this.$store.dispatch(`${this.resourceType}/all`, Object.assign(options, this.additionalOptions))
      }
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.category_id) result += `&category_id=${options.category_id}`
      if (options.tag) result += `&tag=${options.tag}`

      return result
    },

    processDataWithNewQuery() {
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
      this._scrollToListTop()
    },
  },
}
</script>
